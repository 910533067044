<template>
  <v-card flat color="transparent" :loading="loading" :class="$style.teamCard" :style="cssVars">
    <v-card-title class="pt-0" :class="$style.teamCard__title" v-if="team">
      {{ team.displayName }}
    </v-card-title>
    <v-card-subtitle v-if="team && team.stadium" :class="$style.teamCard__location" >{{ team.stadium }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'TeamCard',
  props: {
    team: {
      type: [Object, Promise],
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'start',
    },
  },
  model: {
    prop: ['team'],
    event: 'input',
  },
  computed: {
    cssVars() {
      return {
        '--justify-content': `flex-${this.align}`,
        '--text-align': `${this.align === 'end' ? 'right' : 'left'}`,
      };
    },
  },
};
</script>

<style lang="scss" module>
.teamCard {
  &__title,
  &__location {
    color: #fafafa !important;
  }

  &__title {
    justify-content: var(--justify-content);
    font-size: 16px;
    word-break: break-word;
    line-height: 1;
    text-align: var(--text-align) !important;
  }

  &__location {
    text-align: var(--text-align);
  }
}
</style>
