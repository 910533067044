
import { sanitizeGuid } from '../../../../utils/helpers';
import formatSingleCell from '../../../../utils/DataRow';
import SanitizedRow from './SanitizedRow.mixin';
import TableCell from './TableCell.vue';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';

export default {
  name: 'DataTableRow',
  mixins: [SanitizedRow],
  props: {
    headers: Array,
    item: Object,
    rtl:  {
      type: Boolean,
      default: false,
    },
    unitsSystem: String,
    resultHash: String,
    index: Number,
    select: Function,
    isSelected:  {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Function,
      default: (v) => {},
    },
    isExpanded:  {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showAnomalies: {
      type: Boolean,
      default: false,
    },
    expandIcon: {
      type: String,
      default: '$expand',
    },
    toggleCheckbox: {
      type: Function,
      default: (rid, rrid) => {},
    },
  },
  components: {
    TableCell,
    PlayerProfileLink,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    resultIdToCssId() {
      let guid = this.item.Hit.Data.GUID || this.item.Pitch.Data.GUID;
      if (guid) {
        return sanitizeGuid(guid);
      }
      return null;
    },
  },

  methods: {
    formatSingleCell,
    expandFn(e) {
      e.stopPropagation();
      this.expand(!this.isExpanded);
    },
  },
};
