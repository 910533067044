<template>
  <v-card flat color="transparent" :class="$style.dateCard" :style="cssVars">
    <v-card-subtitle :class="$style.dateCardLabel" class="pa-0">{{ label }}</v-card-subtitle>

    <v-card-title :class="$style.dateCardValue" class="pa-0">
      <core-time :time="value" :format="format"></core-time>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'DateCard',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    cssVars() {
      return {
        '--justify-content': `flex-${this.align === 'right' ? 'end' : 'start'}`,
        '--text-align': `${this.align}`,
      };
    },
  },
};
</script>

<style lang="scss" module>
.dateCard {
  &Label,
  &Value {
    text-align: var(--text-align);
    justify-content: var(--justify-content);
    font-size: 12px;
    line-height: 1;
    color: #fafafa;
    text-decoration: none solid rgb(250, 250, 250);
  }

  &Label {
    opacity: 0.6;
  }
}
</style>
