<template>
  <v-card>
    <v-card-text>
      <v-skeleton-loader type="table-row, table-row">
        <v-simple-table v-if="session.lineScore" :class="$style.scoreCardTable">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center mine-shaft--text body-2">{{ $vuetify.lang.t(text.headers.team) }}</th>
                <th class="text-center mine-shaft--text font-weight-regular" v-for="n in numOfInnings" :key="n">{{ n }}</th>
                <th class="text-center mine-shaft--text font-weight-regular">{{ $vuetify.lang.t(text.headers.runs) }}</th>
                <th class="text-center mine-shaft--text font-weight-regular">{{ $vuetify.lang.t(text.headers.hits) }}</th>
                <th class="text-center mine-shaft--text font-weight-regular">{{ $vuetify.lang.t(text.headers.errors) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td class="mine-shaft--text" v-if="session.awayLineup.team">{{ session.awayLineup.team.displayName }}</td>
                <td class="mine-shaft--text" v-else>…</td>
                <td class="mine-shaft--text" v-for="n in numOfInnings" :key="n">{{ session.lineScore.Away.InningScore[n - 1] }}</td>
                <td class="mine-shaft--text">{{ session.lineScore.Away.R }}</td>
                <td class="mine-shaft--text">{{ session.lineScore.Away.H }}</td>
                <td class="mine-shaft--text">{{ session.lineScore.Away.E }}</td>
              </tr>
              <tr class="text-center">
                <td class="mine-shaft--text" v-if="session.homeLineup.team">{{ session.homeLineup.team.displayName }}</td>
                <td class="mine-shaft--text" v-else>…</td>
                <td class="mine-shaft--text" v-for="n in numOfInnings" :key="n">
                  <template v-if="n === numOfInnings && isConcluded">{{ 'X' }}</template>
                  <template v-else>{{ session.lineScore.Home.InningScore[n - 1] }}</template>
                </td>
                <td class="mine-shaft--text">{{ session.lineScore.Home.R }}</td>
                <td class="mine-shaft--text">{{ session.lineScore.Home.H }}</td>
                <td class="mine-shaft--text">{{ session.lineScore.Home.E }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>{{ $vuetify.lang.t(text.nodata) }}</div>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { Session } from '@/models/orm/Hierarchy';
import { captureException } from '@sentry/vue';

const messages = {
  headers: {
    team: 'Team',
    runs: 'R',
    hits: 'H',
    errors: 'E',
  },
  nodata: 'Score is not available for this session',
};

export default {
  name: 'ScoreBoard',

  props: {
    session: {
      type: Session,
    },
  },

  data() {
    return {
      text: messages,
    };
  },

  computed: {
    numOfInnings() {
      if (this.session && this.session.lineScore) {
        try {
          return Math.max(
            this.session.lineScore.Home.InningScore.length,
            this.session.lineScore.Away.InningScore.length,
          );
        } catch (err) {
          this.$log.warn(`Couldn't get number of innings for ${this.session.id}`);
          captureException(err);
          this.$log.debug(err);
        }
      }

      // fallback
      return 9;
    },
    isConcluded() {
      return this.session?.lineScore?.IsConcluded;
    }
  },
};
</script>

<style lang="scss" module>
.scoreCardTable {
  thead th {
    font-size: rem(12) !important;
    height: 28px;
  }
  tbody td {
    height: 40px;
    font-size: 16px;
    border-right: 1px solid #eaeaea;

    &:first-child {
      border-left: none;
    }
  }
  tbody tr:last-of-type td {
    border-bottom: 1px solid #d7c6cc;
  }
}
</style>
