import { mapGetters, mapMutations } from 'vuex';
import first from 'lodash/first';
import { Providers, Enums } from '@flightscope/baseball-stats';
import ResultPair from '@flightscope/baseball-stats/src/valueObjects/ResultPair';

import DataTableColumnsWrapper from '@/models/DataTableColumnsWrapper';
import { Session, Media } from '@/models/orm/Hierarchy';
import HasTeams from '@/components/mixins/HasTeams';
import exportCSV from '@/utils/CSVResultsExporter';
import { unitSymbolProvider } from '@/filters/units';
import ResultFilter from '@/filters/ResultFilter';
import DeleteResultWith from '@/enums/DeleteResultWith';
import FilterStores from '@/enums/StoreKeys';
import sessionResultsFormatter from '@/formatters/sessionResultsFormatter';
import { scrollTo } from '@/router/scroll';
import { sanitizeGuid, mapRunnersOnSelectToProvider } from '@/utils/helpers';
import formatSingleCell, { playerDetails } from '@/utils/DataRow';

import DataTableFilters from '../components/DataTableFilters.vue';
import DataTableRow from '../components/table/DataTableRow.vue';
import DataTableRowExpanded from '../components/table/DataTableRowExpanded.vue';

import ActionBottomSheet from '../../video-library/components/ActionBottomSheet.vue';

import { videoMapper } from '../../video-library/helpers/videoMapper';
import { captureException } from '@sentry/vue';

/** @typedef {import('../../../global').ProcessedResult} ProcessedResult */

const resultIdToCssId = (item) => {
  let guid = item.Hit.Data.GUID || item.Pitch.Data.GUID;
  if (guid) {
    return sanitizeGuid(guid);
  }
  return null;
};

export default {
  name: 'DataTable',

  mixins: [HasTeams],

  provide() {
    return { context: '', shareEnabled: !!process.env.VUE_APP_SHARE_ENABLED, };
  },

  inject: ['repo'],

  props: {
    session: {
      type: Session,
      required: true,
    },

    SessionRepo: {
      default() {
        return this.repo.get('sessions');
      },
    },

    RosterRepo: {
      default() {
        return this.repo.get('rosters');
      },
    },

    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },

    refreshSession: {
      type: Function,
    },

    stats: Array,
  },

  components: {
    DataTableHeaderWithTitle: () => import('./DataTableHeaderWithTitle'),
    DataTableFilters,
    DataTableRow,
    DataTableRowExpanded,
    ActionBottomSheet,
  },

  data() {
    return {
      loading: false,
      scrollWatcher: null,
      scrolled: false,
      fabExport: false,
      fabDeleteResults: false,

      showVideosDialog: false,
      videosDialogContent: {},

      selectedResultKeys: {},
      dataTableResultsComponentKey: 0,
      DeleteResultWith,

      expanded: [],
      singleExpand: false,
      showExpand: false,

      results: [],

      deletedVideoIds: [],
    };
  },

  computed: {
    ...mapGetters(['isLeagueManagerForCurrentSeason', 'isMaintenanceUser', 'selectedUnitsSystem']),

    dataTableColumnsWrapper() {
      if (this.session) {
        const {
          hasNoHittingData,
          hasNoPitchingData,
          mightHaveRadarData,
          mightHaveScoring,
          mightHaveNewScoringData,
          schemaVer: sessionSchemaVer,
          mightHaveTagging
        } = this.session;
        return new DataTableColumnsWrapper({
          hasNoHittingData,
          hasNoPitchingData,
          mightHaveRadarData,
          mightHaveScoring,
          mightHaveNewScoringData,
          sessionSchemaVer,
          mightHaveTagging
        });
      }

      return null;
    },

    activeFilters() {
      return this.session.filters[FilterStores.DATA_TABLE] || {};
    },

    unitsSystem() {
      return this.selectedUnitsSystem.system;
    },

    indexesOfAvailableColumns() {
      return this.dataTableColumnsWrapper.getIndexesOfAvailableColumns();
    },

    headers() {
      // All header indexes in all variations, for pitching/hitting/scoring.
      // That getIndexesOfVisibleColumns returns indexes of columns, which are allowed in specific variations.
      // I.e. only hitting columns.
      const selectedColumnsIndexes = this.$store.get('user@config.dataTableColumns');
      const allowedColumnsIndexes = this.dataTableColumnsWrapper.getIndexesOfVisibleColumns(selectedColumnsIndexes);
      return this.headersFromIndexes(allowedColumnsIndexes);
    },

    selectedResultIDs() {
      return Object.keys(this.selectedResultKeys);
    },

    watchedProp() {
      return {
        hash: this.$route.hash,
        data: this.session.hasPlayersLoaded,
      };
    },

    resultHash() {
      return this.$route.hash.replace('#', '');
    },

    watchedResults() {
      let values = Object.values(this.activeFilters);

      return {
        loaded: this.session.hasPlayersLoaded,
        values,
        results: this.session?.resultsv1,
      };
    },

    /**
     * Needed for ABS to work properly
     * @returns {ProcessedResult}
     */
    selectedAction() {
      if (this.$route.params.action && this.results?.length) {
        return this.results.find((act) => act.ResultID == this.$route.params.action);
      }
      return null;
    },

    bottomSheetVisible() {
      return !!(this.$route.params.action && this.selectedAction);
    },

    showAnomalies() {
      return this.isMaintenanceUser || this.isLeagueManagerForCurrentSeason || this.showExpand;
    },
  },

  methods: {
    ...mapMutations({setDataTablePage: 'session/setDataTablePage'}),
    // https://michaelnthiessen.com/force-re-render
    async forceRerender() {
      await this.refreshSession();
      this.dataTableResultsComponentKey += 1;
    },

    toggleSelectedResult(ResultID, RelatedResultID) {
      if (this.selectedResultKeys.hasOwnProperty(ResultID)) {
        this.$delete(this.selectedResultKeys, ResultID);
      } else {
        this.$set(this.selectedResultKeys, ResultID, 1);
      }

      if (RelatedResultID === 0) return;

      if (this.selectedResultKeys.hasOwnProperty(RelatedResultID)) {
        this.$delete(this.selectedResultKeys, RelatedResultID);
      } else {
        this.$set(this.selectedResultKeys, RelatedResultID, 1);
      }
    },

    headersFromIndexes(columnIndexes) {
      let mappedHeaders = [];
      const skipUnitFor = ['STRING', 'NUMBER', 'PLAYERNAME', 'DATETIME', 'ENUM'];

      // let's include units where necessary
      for (let i = 0; i < columnIndexes.length; i++) {
        let singleHeader = Object.assign({}, this.dataTableColumnsWrapper.getColumn(columnIndexes[i]));
        if (singleHeader.property && skipUnitFor.indexOf(singleHeader.type) === -1) {
          this.applyUnits(singleHeader);
        }

        mappedHeaders[i] = singleHeader;
      }

      return mappedHeaders;
    },

    applyUnits(singleHeader) {
      let unitsString = unitSymbolProvider(singleHeader.type, this.unitsSystem);
      if (singleHeader.property.stringifiedAs && singleHeader.property.stringifiedAs.length) {
        let unitArr = [];
        unitArr.length = singleHeader.property.stringifiedAs.length;
        unitsString = unitArr.fill(unitsString, 0, singleHeader.property.stringifiedAs.length).join('; ');
      }
      singleHeader.text += ` [${unitsString}]`;
      return singleHeader;
    },

    async findPlayerTagInTeams(tagId) {
      let playerTag = this.RosterRepo.playerOrTag({ teamId: this.session.HomeTeamID, tagId });
      if (playerTag == null) {
        // try with away roster, with placeholderFallback
        playerTag = this.RosterRepo.playerOrTag({ teamId: this.session.AwayTeamID, tagId, placeholderFallback: true });
        if (playerTag == null) {
          return null;
        }
        playerTag.Team = await this.TeamRepo.getTeam(this.session.AwayTeamID);
      } else {
        playerTag.Team = await this.TeamRepo.getTeam(this.session.HomeTeamID);
      }

      return playerTag;
    },

    exportAllHandler(event) {
      const allColumnsIndexes = this.indexesOfAvailableColumns;
      const mappedAllHeaders = this.headersFromIndexes(allColumnsIndexes);
      const csvData = exportCSV(mappedAllHeaders, this.allResultsProcessed(), this.unitsSystem, this.session);
    },

    exportFilteredHandler(event) {
      const csvData = exportCSV(this.headers, this.results, this.unitsSystem, this.session);
      this.$log.debug(csvData);
    },

    /**
     * @param {String[]} deleteModesArray - see available opions in DeleteResultWith enum
     */
    async deleteResults(deleteModesArray) {
      let deleteModesObj = {};

      for (let i = 0; i < deleteModesArray.length; i++) {
        deleteModesObj[deleteModesArray[i]] = 1;
      }

      const deletedResults = this.selectedResultIDs.map((record) => ({ ID: record, ...deleteModesObj }));

      try {
        const resp = await this.SessionRepo.deleteResults(deletedResults);

        for (let ResultID in this.selectedResultKeys) {
          this.$delete(this.selectedResultKeys, ResultID);
        }

        this.forceRerender();
      } catch (error) {
        captureException(error);
        this.$log.error(error);
      }
    },

    /*
    Item to include for session with
    Pitch Count/Plate Appearance (handle reprocessed / scored)
    Balls
    Strikes
    inning number
    inning half
    Loaded bases / Runners on
    Runs
    Errors
    Outs
    */

    /**
     * @param {ResultPair[]} results
     * @param {FormattedResult} results[].hit
     * @param {FormattedResult} results[].pitch
     */
    processResults(results) {
      let vm = this;
      this.loading = true;
      /** @type {ProcessedResult[]} */
      let mergedResults = [];
      let SeqNo = 0;
      for (let index = 0; index < results.length; index++) {
        const resultPair = results[index];
        const { hit, pitch } = resultPair;
        SeqNo++;
        /** @type {ProcessedResult} */
        let result;
        let resultMediaArr = [];
        let batterTagdId = 0;
        let pitcherTagId = 0;

        if (pitch) {
          pitcherTagId = parseInt(pitch.TagID, 10);
          batterTagdId = pitch.RelatedBatterTagID;

          result = {
            SeqNo,
            ResultID: pitch.ResultID,
            RelatedResultID: hit ? hit.ResultID : 0,
            Pitch: pitch,
            Hit: hit || { CreateDate: pitch.CreateDate, Data: pitch.Data },
            Pitcher: this.PlayerRepo.getDefault(0),
            Batter: this.PlayerRepo.getDefault(0),
          };

          if (pitch.Media?.length) {
            resultMediaArr = resultMediaArr.concat(pitch.Media);
          }

          if (pitch.IsInvalid) {
            result.hasAnomalies = pitch.IsInvalid;
            let anomaly = this.invalidResults.find((o) => o.ResultID == result.ResultID);
            result.anomalySrc = anomaly;
          }
        }

        if (hit) {
          let hasAnomalies;
          let anomalySrc;

          if (hit.Media?.length) {
            resultMediaArr = resultMediaArr.concat(hit.Media);
          }

          if (result) {
            ({ hasAnomalies, anomalySrc } = result);
          }

          result = {
            SeqNo,
            ResultID: hit.ResultID,
            RelatedResultID: pitch ? pitch.ResultID : 0,
            // If rawResult.pitch is not available, use CreateDate, Data from hit to display in UI
            Pitch: pitch || { CreateDate: hit.CreateDate, Data: hit.Data },
            Hit: hit,
            Pitcher: this.PlayerRepo.getDefault(0),
            Batter: this.PlayerRepo.getDefault(0),
          };

          if (hasAnomalies) {
            result = { ...result, hasAnomalies, anomalySrc };
          }

          if (hit.IsInvalid) {
            result.hasAnomalies = hit.IsInvalid;
            let anomaly = this.invalidResults.find((o) => o.ResultID == result.ResultID);
            result.anomalySrc = anomaly;
          }

          if (!batterTagdId && hit.TagID) {
            batterTagdId = hit.TagID;
          }
        }

        if (pitcherTagId) {
          let pitcher = this.session.getPlayer(pitcherTagId);

          if (pitcher) {
            result.Pitcher = pitcher;
            result.PitcherName = playerDetails(result.Pitcher);
          } else {
            pitcher = this.PlayerRepo.getDefault(pitcherTagId);
            result.Pitcher = pitcher;
            result.PitcherName = playerDetails(pitcher);
            this.$log.debug(`Could not find pitcher ${pitcherTagId}`);
          }
        }

        if (batterTagdId) {
          let batter = this.session.getPlayer(batterTagdId);

          if (batter) {
            result.Batter = batter;
            result.BatterName = playerDetails(result.Batter);
          } else {
            batter = this.PlayerRepo.getDefault(batterTagdId);
            result.Batter = batter;
            result.BatterName = playerDetails(batter);
            this.$log.debug(`Could not find batter ${batterTagdId}`);
          }
        }

        /** @type {Media[]} */
        result.Media = resultMediaArr.map(this.session.mapMediaForResult.bind(this.session)).filter((m) => m.isVideo);

        if ((this.isLeagueManagerForCurrentSeason || this.isMaintenanceUser) && result.hasAnomalies) {
          let formattedResults;
          let formattedResult;

          if (result.anomalySrc.Data) {
            formattedResults = sessionResultsFormatter([result.anomalySrc]);
            formattedResult = first(formattedResults);

            if (formattedResult.Data) {
              result.anomalySrc = formattedResult;
            }
          } else {
            result.anomalySrc = null;
          }
        } else {
          result.hasAnomalies = false;
        }

        if (result.Hit || result.Pitch) {
          // to be compatible with player profile dataset
          result.results = resultPair;
          mergedResults.push(result);
        }
      }
      this.loading = false;
      return mergedResults;
    },

    scrollWhenNeeded(data) {
      if (data.hash && data.data && !this.scrolled) {
        this.$nextTick(() => {
          scrollTo(data.hash, {
            container: '.fixedTable .v-data-table__wrapper',
            appOffset: false,
            offset: 200,
          });
          this.scrolled = true;
        });
      }
    },

    resultIdToCssId,
    formatSingleCell,

    /**
     *
     * @returns {ResultPair[]}
     */
    allPairs() {
      return Providers.DataTableDataProvider(this.session.resultsv1);
    },

    allResultsProcessed() {
      return this.processResults(this.allPairs());
    },

    dataFilters() {
      let filters = {
        pitcherIds: this.activeFilters[ResultFilter.Pitchers.key],
        pitchResults: this.activeFilters[ResultFilter.PitchResult.key],
        pitchTypes: this.activeFilters[ResultFilter.PitchType.key],
        batterIds: this.activeFilters[ResultFilter.Batters.key],
        batterHandedness: this.activeFilters[ResultFilter.BatterHandedness.key],
        pitcherHandedness: this.activeFilters[ResultFilter.PitcherHandedness.key],
        zones: this.activeFilters[ResultFilter.Zones.key],
        attackRegions: [],
      };
      if (process.env.VUE_APP_SPORT_TYPE == Enums.SportType.Baseball.key) {
        filters = {
          ...filters,
          pitchSet: this.activeFilters[ResultFilter.PitchSet.key],
        };
      }
      if (this.session.mightHaveScoring) {
        filters = {
          ...filters,
          outs: this.activeFilters[ResultFilter.ScoringOuts.key],
          balls: this.activeFilters[ResultFilter.ScoringBalls.key],
          strikes: this.activeFilters[ResultFilter.ScoringStrikes.key],
          runnersOn: mapRunnersOnSelectToProvider(this.activeFilters[ResultFilter.ScoringRunnersOn.key]),
        };
      }
      return filters;
    },

    filteredPairs() {
      const filters = this.dataFilters();
      return Providers.DataTableDataProvider(this.session.resultsv1, undefined, filters);
    },

    filterResults() {
      this.results = this.processResults(this.filteredPairs());
    },

    maybeHideDialog() {
      if (this.videosDialogContent) {
        const stillExists = this.videosDialogContent.media
          .map((m) => m.id)
          .filter((id) => this.session.mediaForResult.find((mfr) => mfr.id === id));

        if (stillExists.length) {
          const { media } = this.videosDialogContent;

          this.videosDialogContent.media = media.filter((m) => stillExists.includes(m.id));

          return false;
        }
      }
      this.showVideosDialog = false;
      this.videosDialogContent = null;

      return true;
    },

    onFiltersWatch(val, oldVal) {
      if (!this.session.hasPlayersLoaded) {
        return;
      }
      if (!val.results.length) {
        this.results = [];
        return;
      }

      if (val?.loaded && !oldVal?.loaded) {
        this.loading = true;
        this.filterResults();
        this.loading = false;
      } else if (val?.loaded) {
        if (val?.values !== oldVal?.values) {
          this.loading = true;
          this.filterResults();
          this.loading = false;
        }
      }

      if (this.showVideosDialog) {
        this.maybeHideDialog();
      }
    },

    actionMapper(item) {
      const notDeletedMedia = item.Media
        .filter((vid) => !this.deletedVideoIds.includes(vid.id))
        .map(videoMapper);

      // let date = item.Hit.CreateDate || item.Pitch.CreateDate;
      // let pitcher = `${item.Pitcher.FormattedNameWithJersey} (P: ${item.Pitch.Data.PITCH_COUNT_R})`;
      // let batter = `${item.Batter.FormattedNameWithJersey} (PA: ${item.Hit.Data.PLATE_APPEARANCE_R})`;
      // let pitchType = Providers.EnumValueProvider.getValue(item.Pitch.Data.PITCH_CLASSIFICATION, GenericPitchType, 'UI')
      //   .name;
      // let pitchResult = Providers.EnumValueProvider.getValue(item.Pitch.Data.PITCH_RESULT, Enums.PitchResult, 'UI')
      //   .name;
      // let title = `${pitcher} vs ${batter}: ${pitchType || ''} / ${pitchResult || ''}`;
      // return {
      //   date,
      //   title,
      //   media: item.Media,
      // };

      return {
        id: item.ResultID,
        player: this?.player?.Id ? this.player : null,
        results: item?.results,
        media: notDeletedMedia,
      };
    },
  },

  watch: {
    watchedResults: {
      handler: 'onFiltersWatch',
      immediate: true,
      deep: true,
    },
  },

  updated() {
    this.scrollWhenNeeded(this.watchedProp);
  },

  mounted() {
    this.setDataTablePage(true);

    if (!this.scrollWatcher) {
      this.scrollWatcher = this.$watch(
        'watchedProp',
        (newVal) => {
          this.scrollWhenNeeded(newVal);
        },
        {
          immediate: true,
        },
      );
    }
  },

  beforeDestroy() {
    this.setDataTablePage(false);

    this.scrollWatcher();
  },
};
