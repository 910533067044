<template>
  <component
    :is="comp || 'v-select'"
    v-bind="$attrs"
    v-model="selected"

    :name="name"
    :menu-props="menuProps"
    :items="items"
    :multiple="multiple"

    hide-details
    dense
    outlined
  >
  <template v-if="multiple" v-slot:selection="{ item, index }">
    <template v-if="chipped">
      <v-chip v-if="index === 0">
        <span>{{ item.text }}</span>
      </v-chip>
      <span
        v-if="index === 1"
        class="grey--text caption"
      >(+{{ selected.length - 1 }} others)</span>
    </template>
    <template v-else>
      <template v-if="index === 0">
        <span v-if="allItems || selected.length === 0">{{ allItemsText }}</span>
        <span v-if="someItems && selected.length > 1">{{ someItemsText }}</span>
        <span v-if="!allItems && selected.length === 1">{{ item.text }}</span>
      </template>
    </template>
  </template>

  <template v-if="multiple && showAll" v-slot:prepend-item>
    <v-list-item
      ripple
      @click="toggleAll"
    >
      <v-list-item-action>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ allItemsText }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      ripple
      @click="() => toggleGroup(group.group)"
      v-for="(group, i) in groups" :key="`toggle${i}`"
    >
      <v-list-item-action>
        <v-icon>{{ iconGroup(group.group) }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ `All from ${group.header}` }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mt-2"></v-divider>
  </template>
  </component>
</template>

<script>
import difference from 'lodash/difference';
import Messages from '@/enums/Messages';
import TextFormatter from '@/formatters/TextFormatter';

export default {
  name: 'CustomSelect',

  props: {
    name: {
      type: String,
    },
    value: {
      type: [Array, String, Number],
      required: true,
    },
    listeners: {
      type: Object,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    chipped: {
      type: Boolean,
      default: false,
    },
    allItemsText: {
      type: String,
      default: Messages.ALL,
    },
    forceFirst: {
      type: Boolean,
      default: false,
    },
    comp: {
      default: 'v-select',
    },
  },

  data() {
    return {
      menuProps: {
        offsetY: true,
        maxHeight: '80vh',
      },
      forced: false,
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    allItems() {
      return this.selected.length === this.justOptions.length;
    },

    someItems() {
      return this.selected.length > 0 && !this.allItems;
    },

    icon() {
      if (this.allItems) return 'mdi-close-box';
      if (this.someItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

    someItemsText() {
      return TextFormatter.selectedMultipleItems(this.selected.length, this.label);
    },

    otherItemsText() {
      return TextFormatter.otherItemsSuffix(this.selected.length - 1);
    },

    justOptions() {
      return this.items.filter((item) => {
        return item.hasOwnProperty('value');
      });
    },

    groups() {
      return this.items.reduce((acc, curr) => {
        if (curr.header) {
          acc.push(curr)
        }

        return acc;
      }, [])
    },

    watched() {
      return {
        selected: this.selected,
        items: this.items.length,
      };
    },
  },

  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.allItems) {
          this.selected = [];
        } else {
          this.selected = this.justOptions.map(option => option.value);
        }
      });
    },

    toggleGroup(options) {
      this.$nextTick(() => {
        const allGroupItems = difference(this.selected, options).length === 0;

        if (this.allItems) {
          this.selected = difference(this.selected, options);
          }
        else if (allGroupItems && this.selected.length !== 0) {
          this.selected = [];
        } else {
          this.selected = options;
        }
      });
    },

    handler({ items, selected }, oldVal) {
      if (this.forceFirst) {

        if (items && !selected && !this.forced) {
          let first = this.items.find(i => i.value);
          if (first?.value) {
            this.selected = first.value;
            this.forced = true;
          };
        }
      }
    },


    iconGroup(options) {
      const allGroupItems = difference(options, this.selected).length === 0;
      const some = this.selected.length > 0 && this.selected.some(i => options.includes(i));

      if (allGroupItems) return 'mdi-close-box';
      if (some) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  watch: {
    watched: {
      handler: 'handler',
      immediate: true,
    },
  },
}
</script>
