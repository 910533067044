
<script>
import { SET_TEAM } from '../../store/actions/sessions';
import { concatAndDeDuplicate } from '../../utils/helpers';

export default {
  name: 'TeamSelectorFilter',
  props: {
    module: {
      type: String,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
      baseTeam: [{ TeamID: 0, TeamDisplayName: 'All Teams' }],
    };
  },
  computed: {
    allTeams() {
      return concatAndDeDuplicate(this.baseTeam, this.teams);
    },
    team: {
      get() {
        return this.$store.state.sessions[this.module].filter.team;
      },
      set(value) {
        this.$store.dispatch(SET_TEAM, { module: this.module, key: '', value });
      },
    },
  },
};
</script>

<template>
  <v-select
    v-model="team"
    :items="allTeams"
    :menu-props="menuProps"
    item-text="TeamDisplayName"
    item-value="TeamID"
    label="Team"
    dense
    hide-details
    outlined
  ></v-select>
</template>
