export default {
  namespaced: true,
  state() {
    return {
      isDataTablePage: false
    };
  },
  getters: {
    isDataTablePage: (state) => state.isDataTablePage
  },
  mutations: {
    setDataTablePage: (state, boolean) => {state.isDataTablePage = boolean}
  },
  actions: {
    initialize({ dispatch }) {
      // console.info('Module initializing...');
      // console.info('Module initialized.');
    },
  },
};
