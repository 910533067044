<template>
  <td :class="styleClass">
    <v-snackbar v-if="isTooLong"
      v-model="clipboardSnackbarVisible"
      color="green"
      :timeout="2000"
      :top="true"
    >
      {{ $vuetify.lang.t('$vuetify.tableCell.clipboardSnackbar.successText') }}
      <template>
        <v-btn
          dark
          text
          @click="clipboardSnackbarVisible = false"
        >{{ $vuetify.lang.t('$vuetify.tableCell.clipboardTooltipCloseActionText') }}</v-btn>
      </template>
    </v-snackbar>

    <div :class="{'cell-text-overflow': this.isTooLong}"
      :title="isTooLong ? `${$vuetify.lang.t('$vuetify.tableCell.clipboardTooltipText')}: ${value}` : null"
      @click="onClick">
      <slot>
        <!-- <sanitized-cell
          v-if="isValidationScope || isSanitizationScope"
          :value="value"
          :is-validation="isValidationScope"
          :is-sanitization="isSanitizationScope"
          :messages="messages"
          :message-key="check"
        ></sanitized-cell> -->
        <template>{{ value }}</template>
      </slot>
    </div>
  </td>
</template>

<script>
import SanitizedCell from './SanitizedCell.vue';
import { copyToClipboard } from '@/utils/helpers';
import { VSnackbar } from 'vuetify/lib';

const headerValueToScope = (header) => {
  if (!header.hasOwnProperty('property') || header.property === null) {
    return false;
  }

  if (header.property.hasOwnProperty('parent')) {
    return `Data.${header.property.parent}`;
  }

  // todo - use header.path
  return `Data.${header.property.key}`;
};

export default {
  name: 'TableCell',
  props: {
    header: Object,
    value: {
      type: [String, Object, Number],
      default: '-',
    },
    canExpand: {
      type: Boolean,
      default: false,
    },
    scopes: {
      type: Object,
      default: () => ({
        validation: [],
        sanitization: [],
        invalidated: [],
      }),
    },
    messages: {
      type: Object,
      default: () => ({
        validation: [],
        sanitization: [],
      }),
    },
  },
  components: {
    SanitizedCell,
    VSnackbar
  },
  data() {
    return {
      displayedValue: this.value,
      clipboardSnackbarVisible: false,
    };
  },
  computed: {
    styleClass() {
      return {
        warning: this.warning,
        error: this.isValidationScope,
        [this.header.class]: this.header.class,
        expandable: this.expandable,
      };
    },

    check() {
      return headerValueToScope(this.header);
    },

    warning() {
      return this.isInvalidated && !this.isValidationScope;
    },

    expandable() {
      return this.header.value == 'data-table-expand';
    },

    isTooLong() {
      return this.value?.length > 1 && (this.header.isTooLong || false);
    },

    isInvalidated() {
      return this.isScope('invalidated');
    },

    isValidationScope() {
      return this.isScope('validation');
    },

    isSanitizationScope() {
      return this.isScope('sanitization');
    },
  },
  methods: {
    isScope(scope) {
      if (this.check) {
        return this.canExpand && this.scopes[scope].includes(this.check);
      }
      return false;
    },
    onClick() {
      if (this.isTooLong) {
        this.clipboardSnackbarVisible = true;
        copyToClipboard(this.value)
      }
    }
  },
};
</script>

<style scoped>
.cell-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70px;
  cursor: copy;
}
</style>
