<template>
  <v-card class="Site session-card">
    <v-data-table v-if="isDataReady"
      :headers="filteredTableHeaders"
      :items="sessions"
      :options.sync="options"
      :loading="loading"
      :fixed-header="fixedHeader"
      :disable-pagination="true"
      :disable-filtering="true"
      :disable-sort="true"
      hide-default-footer
      class="elevation-1 Site-content session-table"
      @click:row="navigateTo"
    >

    <template #item.dataVerified="{ item, value }">
      <dfn
        v-if="sessionType === 'games'"
        :title="`Data ${value?'':'not '}verified`"
        class="pa-1 rounded-circle d-inline-block" :class="value?'success':'error'"
      ></dfn>
    </template>

    <template #item.resultTypes.H="{ item, value }">
      <span>{{ typeof value === 'undefined' ? item['resultsCount'] : value }}</span>
    </template>

    <template #item.resultTypes.P="{ item, value }">
      <span>{{ typeof value === 'undefined' ? item['resultsCount'] : value }}</span>
    </template>

    <template v-slot:item.createDate="{ item }">
      <core-time :time="item.createDate" />
    </template>

    <template v-slot:item.endDate="{ item }">
      <core-time :time="item.endDate" />
    </template>

    <template v-slot:item.lineupTeamsOrganizationCodes[0]="{ value }">
      {{ value.tagIDs.length }}
    </template>

    <template v-slot:item.displayName="{ item }">
      <team-result-chip v-if="showScoreChip(item)" small
        v-bind:homeTeamId="item.HomeTeam.ID"
        v-bind:awayTeamId="item.AwayTeam.ID"
        v-bind:homeTeamRuns="item.lineScore.Home.R"
        v-bind:awayTeamRuns="item.lineScore.Away.R"
        v-bind:fromPerspectiveOfTeamId="determineManagedTeamId(item)"
      ></team-result-chip>
      <router-link
        :to="{ name: 'session', params: { type: item.sessionType, id: item.id }}"
      >{{ item.displayName || 'Untitled game' }}</router-link>
    </template>

    <template v-slot:item.AwayTeam="{ item }">
      <team-logo-url
        :size="30"
        :url="item.AwayTeam.Url"
        :name="item.AwayTeam.Name"
        :short-code="item.AwayTeam.ShortCode"
        displayName></team-logo-url>
    </template>

    <template v-slot:item.HomeTeam="{ item }">
      <team-logo-url
        :size="30"
        :url="item.HomeTeam.Url"
        :name="item.HomeTeam.Name"
        :short-code="item.HomeTeam.ShortCode"
        displayName></team-logo-url>
    </template>

    <template v-slot:no-results>
      <no-data message="There are no results"></no-data>
    </template>

    <template v-slot:no-data>
      <no-data></no-data>
    </template>
    </v-data-table>

    <div v-else>Loading...</div>

    <div v-if="isDataReady" class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="numOfPages"
          :total-visible="8"
          :disabled="loading"
        ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import STATES from '../store/actions/states';
import TeamLogoUrl from './team/TeamLogoUrl.vue';
import TeamResultChip from './SessionList/TeamResultChip.vue';
import NoData from '@/components/core/NoData.vue';
import {
  SESSIONS_REQUEST, CLEAR_SESSION_LIST,
  SET_DATES, SET_PLACE, SET_TEAM, SET_OPPONENTS, SET_PAGE,
  SET_ATTENDANTS,
} from '../store/actions/sessions';

export default {
  name: 'SessionsList',
  metaInfo: {
    title: 'Sessions List',
  },
  props: {
    tableHeaders: {
      type: Array,
    },
    module: {
      type: String,
      required: true,
      default: 'games',
    },
  },
  components: {
    TeamLogoUrl,
    TeamResultChip,
    NoData,
  },
  data() {
    return {
      fixedHeader: true,
      options: {},
      filterChangeWatcher: null,
      page: 1,
      numOfPages: 1,
    };
  },
  computed: {
    ...mapGetters('app', ['filters']),
    ...mapGetters('entities/teams', ['allEventTeamIDs']),
    ...mapGetters([
      'isLeagueManagerForCurrentSeason',
      'isProfileLoaded',
      'userTeamIDs',
      'currentProfile',
      'isMaintenanceUser',
      'hasRoleInTeam'
      ]),
    ...mapState({
      loading(state) {
        return state.sessions[this.module].filter.status === STATES.LOADING;
      },
      isUserDataReady(state) {
        return state.user.status === STATES.SUCCESS;
      },
      currentPage(state) {
        return (state.sessions[this.module].filter.offset / state.sessions.maxRecords) + 1;
      },
      totalPages(state) {
        return Math.ceil(state.sessions[this.module].total / state.sessions.maxRecords);
      },
      sessions(state) {
        return state.sessions[this.module].list;
      },
    }),

    sessionType() {
      return this.module;;
    },

    isDataReady() {
      return this.isUserDataReady && !this.$store.state.entities.teams.fetching;
    },

    showScoreChip() {
      let vm = this;
      let notLMorAdm = !this.isMaintenanceUser && !this.isLeagueManagerForCurrentSeason;

      return (item) => {
        return notLMorAdm
          && item.lineScore?.Home
          && item.lineScore?.Away
          && vm.determineManagedTeamId(item) !== false;
      };
    },
    availableTeamIDs() {
      return (this.isLeagueManagerForCurrentSeason ? this.allEventTeamIDs : this.userTeamIDs);
    },
    filteredTableHeaders() {
      // showing team display name has sense in P/H practive only in case where
      // logged user has access to multiple teams (operator or manager of 2+ teams)
      // this.tableHeaders
      if (this.availableTeamIDs.length > 1) {
        return this.tableHeaders;
      }
      return this.tableHeaders.filter(header => header.value !== 'HomeTeamDisplayName');
    },
  },
  created() {
    this.$log.debug('Sessions list created.');
    this.$store.dispatch(CLEAR_SESSION_LIST, this.module);
    this.$log.debug('Sessions list erased.');
    this.debouncedFetchSessions = debounce(this.fetchSessions, 600);
  },
  mounted() {
    const fetchOnActions = [
      'app/setFilters.season', SET_DATES, SET_PLACE, SET_TEAM, SET_OPPONENTS, SET_PAGE, SET_ATTENDANTS,
    ];
    this.$log.debug('Sessions list mounted.');
    if (this.isProfileLoaded) {
      this.fetchSessions();
    }

    this.listWatcher = this.$store.subscribeAction({
      after: (action, state) => {
        if (fetchOnActions.includes(action.type)) {
          this.$log.debug('Action', action);
          this.$log.debug('State', state.sessions[this.module].filter);
          this.debouncedFetchSessions();
        } else if (action.type === SESSIONS_REQUEST) {
          this.numOfPages = this.totalPages;
          this.page = this.currentPage;
        }
      },
    });
  },
  methods: {
    determineManagedTeamId(session) {
      // TODO: double-check conditions with new schema
      // eslint-disable-next-line no-nested-ternary
      return this.hasRoleInTeam(session.HomeTeam.ID)
        ? session.HomeTeam.ID
        : (this.hasRoleInTeam(session.AwayTeam.ID) ? session.AwayTeam.ID : false);
    },

    fetchSessions() {
      this.$store.dispatch(SESSIONS_REQUEST, this.module);
      this.$log.debug('Sessions list requested.');
    },

    navigateTo(item) {
      this.$router.push({
        name: 'session',
        params: { type: item.sessionType, id: item.id },
      });
    },
  },
  watch: {
    page: {
      handler() {
        if (this.currentPage !== this.page) {
          this.$store.dispatch(SET_PAGE, { module: this.module, pageNum: this.page });
        }
      },
    },
    isProfileLoaded(newValue) {
      if (newValue) {
        this.fetchSessions();
      }
    },
    module(val) {
      if (this.isProfileLoaded) {
        this.fetchSessions();
      }
    },
  },

  beforeDestroy() {
    this.listWatcher();
  },
};
</script>

<style lang="scss" scoped>
.session-card {
  --max-h: calc(100vh - 60px - 56px - 16px);

  .session-table {
    --max-h: calc(100vh - 60px - 56px - 16px - 52px);
    max-height: var(--max-h);
  }
}

.session-table::v-deep {
  td {
    height: 40px;
  }
  tbody tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
