<template>
<tr :key="index" class="info white--text">

  <table-cell v-for="(header, headerIndex) in headers"
    :key="headerIndex"
    :header="header"
    :can-expand="canExpand"
    :scopes="scopes"
    :messages="messages">

    <template v-slot>
      <div v-if="disabledCells.includes(header.value)">
        &nbsp;
      </div>
      <div v-else>{{ formatInvalidCell(header) }}</div>
    </template>
  </table-cell>

</tr>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { concatAndDeDuplicate, sanitizeGuid } from '@/utils/helpers';
import formatSingleCell, { playerDetails } from '@/utils/DataRow';
import SanitizedRow from './SanitizedRow.mixin';
import TableCell from './TableCell.vue';

export default {
  name: 'DataTableRowExpanded',

  mixins: [SanitizedRow],

  props: {
    headers: Array,
    item: Object,
    unitsSystem: String,
    index: Number,
    showAnomalies: Boolean,
  },
  components: {
    TableCell,
  },
  data() {
    return {
      // MUST not be empty
      disabledCells: [
        'data-table-expand', 'SeqNo', 'Media',
      ],
    };
  },
  computed: {
    //
  },

  methods: {
    mapHeaderToInvalid(header) {
      let h = cloneDeep(header);
      if (Array.isArray(h.path) && h.path.length && ['Hit', 'Pitch'].includes(h.path[0])) {
        h.path[0] = 'anomalySrc';
      }
      return h;
    },

    formatInvalidCell(header) {
      let newHeader = this.mapHeaderToInvalid(header);
      return formatSingleCell(this.item, newHeader, this.index, this.unitsSystem, false);
    },

    expandFn(e) {
      e.stopPropagation();
      this.expand(!this.isExpanded);
    },
  },
};
</script>
