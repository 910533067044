<template>
  <v-card dark tile color="header_bg" :loading="!session" :class="[$style.sessionInfo, isSlim ? 'slim' : '']" class="session-info">
    <v-container>
      <v-row>
        <wrapper v-if="session">
        <v-row no-gutters>
        <v-col cols="12">

          <div class="info-grid">
            <team-logo-url
              class="awayAvatar"
              :size="avatarSize"
              v-if="lineupA && lineupA.team"
              :url="lineupA.team.logo"
              :name="lineupA.team.displayName"
              :short-code="lineupA.team.ShortCode"></team-logo-url>

            <div class="divider text-center" :class="$style.gameInfo__season">
                {{ [typeLabel, sessionSeason ].join(' - ') }}
            </div>

            <team-card
              class="away"
              v-if="lineupA && lineupA.team"
              :loading="!lineupA.team"
              :team="lineupA.team"
            ></team-card>

            <date-card
              class="create pr-4"
              :value="session.createDate"
              :format="timeFormat"
              :label="text.dateCreated"
              align="right"
            ></date-card>

            <date-card
              class="last pl-4"
              v-if="session.modificationDate"
              :value="session.modificationDate"
              :format="timeFormat"
              :label="text.dateLast"
            ></date-card>

            <team-card
              class="home"
              v-if="lineupB"
              :loading="!lineupB.team"
              :team="lineupB.team"
              align="end"></team-card>

            <team-logo-url
              class="homeAvatar"
              :size="avatarSize"
              v-if="lineupB && lineupB.team"
              :url="lineupB.team.logo"
              :name="lineupB.team.displayName"
              :short-code="lineupB.team.ShortCode"></team-logo-url>

            <div class="middle text-center" :class="{'has-verified' : session.sessionType == Enums.SessionType.Game.key }">
              <div v-if="session.sessionType == 'Game'" class="verified text-caption" >
                <div
                  class="pa-1 rounded-circle d-inline-block" :class="dataIcon"
                ></div>
                <span class="ml-1" >{{ dataText }}</span>
                <span v-if="$can('manage', session)"> - </span>
                <span v-if="$can('manage', session)"
                  class="text-decoration-underline"
                  role='button'
                  @click="dataVerifiedPopup = !dataVerifiedPopup">Change</span>
              </div>
              <div class="homeScore display-1">
                  <span v-if="session.lineScore">{{ session.lineScore.Home.R }}</span>
              </div>
              <div class="awayScore display-1">
                  <span v-if="session.lineScore">{{ session.lineScore.Away.R }}</span>
              </div>
            </div>
          </div>

        </v-col>
        </v-row>
        </wrapper>
      </v-row>
    </v-container>

    <session-edit-dialog
      v-if="$can('manage', session) && dataVerifiedPopup" v-model="dataVerifiedPopup"
      :refresh-session="refreshSession"
      :session="session"
    ></session-edit-dialog>
  </v-card>
</template>

<script>
import { Session } from '@/models/orm/Hierarchy';
import Wrapper from '@/components/ui/Wrapper.vue';
import TeamLogoUrl from '@/components/team/TeamLogoUrl.vue';
import TeamCard from '@/components/session/info/TeamCard.vue';
import DateCard from '@/components/session/info/DateCard.vue';
import { Enums } from '@flightscope/baseball-stats';
import SessionEditDialog from './SessionEditDialog.vue';
import { SessionType } from '@/store/actions/api';

const messages = {
  dateCreated: 'Created',
  dateLast: 'Last Modified',
};

export default {
  name: 'SessionInfo',

  components: {
    Wrapper,
    TeamLogoUrl,
    TeamCard,
    DateCard,
    SessionEditDialog,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },
    isSlim: {
      type: Boolean,
      default: false
    },

    refreshSession: Function,
  },

  data() {
    return {
      timeFormat: 'YYYY-MM-DD HH:mm',
      text: messages,

      dataVerifiedPopup: false,
      Enums,
    };
  },
  computed: {
    avatarSize() {
      // return this.isSlim ? 50 : 60;
      return 60;
    },
    typeLabel() {
      return this.$vuetify.lang.t(`$vuetify.sessionTypes.${this.session.sessionType}`);
    },
    sessionSeason() {
      let home = this.session.homeLineup?.team?.season;
      let away = this.session.awayLineup?.team?.season;

      if (home) {
        if (away && home.id !== away.id) {
          this.$log.debug('Different seasons');
        }
        return home.displayName;
      }
      return null;
    },
    dataIcon() {
      return this.session?.dataVerified?'success':'error';
    },
    dataText() {
      return this.session?.dataVerified? 'Data verified' : 'Data not verified';
    },
    lineupA() {
      if (this.session.mightHaveTwoTeams) {
        return this.session.awayLineup;
      }
      return this.session.homeLineup;
    },
    lineupB() {
      if (this.session.mightHaveTwoTeams) {
        return this.session.homeLineup;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" module>
.sessionInfo {
  min-height: 101px;
}
.gameInfo {
  &__season {
    font-size: 14px;
    color: #a9a9a9;
  }
}
</style>


<style lang="scss" scoped>
.info-grid {
  display: grid;
  grid-template-columns: auto 1fr 1fr auto auto 1fr 1fr auto;
  grid-template-rows: auto auto auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "awayAvatar divider divider divider divider divider divider homeAvatar"
    "awayAvatar away create middle middle last home homeAvatar"
    "awayAvatar away create middle middle last home homeAvatar";
}

.homeAvatar { grid-area: homeAvatar; }

.divider {
  grid-area: divider;
}
.left { grid-area: left; }
.center { grid-area: center; }
.right { grid-area: right; }

.home { grid-area: home; }

.create { grid-area: create; }

.last { grid-area: last; }

.away { grid-area: away; }

.awayAvatar { grid-area: awayAvatar; }

.middle {
  display: grid;
  grid-template-columns: repeat(1fr auto 1fr);
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "homeScore verified awayScore";
  grid-area: middle;

  &.has-verified {
    gap: 0px 1em;
  }
}

.verified { grid-area: verified; }

.homeScore { grid-area: homeScore; min-width: 2em; }

.awayScore { grid-area: awayScore; min-width: 2em; }

.home, .away {
  &::v-deep .v-card__title {
    word-break: break-word;
    line-height: 1;
  }
}

.away {
  &::v-deep .v-card__title {
    text-align: right;
  }
}

.session-info{
  transition: min-height .3s ease-in-out;

  .container, .row, .row > div {
    transition: padding .3s ease-in-out;

  }
  .awayAvatar, .homeAvatar {
    transition: transform .3s ease-in-out;
  }

  &.slim{
    min-height: unset;
    .container, .row, .row > div {
      padding-bottom: 0;
    }

    .awayAvatar, .homeAvatar {
      transform: scale(0.8);
    }
  }
}
</style>
