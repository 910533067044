// session details
import LinksPerRouteProvider from '@/providers/LinksPerRouteProvider';
import Summary from '@/modules/session/views/Summary.vue';
import DataTable from '@/modules/session/views/DataTable.vue';
import ReportWithFilters from '@/modules/session/views/ReportWithFilters.vue';
import PitchingSummary from '@/modules/session/views/PitchingSummary.vue';
import HittingSummary from '@/modules/session/views/HittingSummary.vue';
import FilterStores from '@/enums/StoreKeys';
import { Enums } from '@flightscope/baseball-stats';

const isSoftball = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key;

const PitchingDetailsChildren = [
  {
    path: 'summary',
    name: 'session.report.pitching.summary',
    component: PitchingSummary,
    meta: {
      icon: 'list',
      text: 'Summary',
      filters_key: FilterStores.PITCHING_SUMMARY,
    },
  },
  {
    path: 'release-point-chart/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.pitching.release-point',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartReleasePoint',
      text: '$vuetify.reports.releasePointChart',
      filters_key: FilterStores.RELEASE_POINT,
    },
  },
  {
    path: 'pitch-location-chart/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.pitching.pitch-location',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartPitchLocation',
      text: '$vuetify.reports.pitchLocationChart',
      filters_key: FilterStores.LOCATION_CHART,
    },
  },
  {
    path: 'release-extension-chart/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.pitching.release-extension',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: isSoftball ? 'chartReleaseExtensionSoftball' : 'chartReleaseExtension',
      text: '$vuetify.reports.releaseExtensionChart',
      filters_key: FilterStores.RELEASE_EXTENSION,
    },
  },
  {
    path: 'pitch-movement-chart/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.pitching.pitch-movement',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartPitchMovement',
      text: '$vuetify.reports.pitchMovementChart',
      filters_key: FilterStores.PITCH_MOVEMENT,
    },
  },
  {
    path: 'pitcher-performance-tracking/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.pitching.pitcher-performance',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartPitcherPerformacnce',
      text: '$vuetify.reports.pitcherPerformanceTracking',
      filters_key: FilterStores.PITCH_TRACKING,
    },
  },
  {
    path: 'post-game',
    name: 'session.report.pitching.post-game',
    component: () => import('@/modules/session/views/PostGame.vue'),
    meta: {
      icon: 'list',
      text: '$vuetify.reports.postGame',
      filters_key: FilterStores.PITCHING_POST_GAME,
      require: 'stats',
    },
    props: {
      context: 'pitching',
    }
  },
];

const HittingDetailsChildren = [
  {
    path: 'summary',
    name: 'session.report.hitting.summary',
    component: HittingSummary,
    meta: {
      icon: 'list',
      text: 'Summary',
      filters_key: FilterStores.HITTING_SUMMARY,
    },
  },
  {
    path: 'contact-point/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.hitting.contact-point',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartContactPoint',
      text: '$vuetify.reports.contactPointChart',
      filters_key: FilterStores.CONTACT_POINT,
    },
  },
  {
    path: 'strike-zone/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.hitting.strike-zone',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartPitchLocation',
      text: '$vuetify.reports.strikeZoneChart',
      filters_key: FilterStores.STRIKEZONE_CHART,
    },
  },
  {
    path: 'hit-spray-chart/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.hitting.spray-chart',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartBatterSpray',
      text: '$vuetify.reports.sprayChart',
      filters_key: FilterStores.HIT_SPRAY,
    },
  },
  {
    path: 'batter-performance-tracking/:action?/:vid(\\d+)?/:share?',
    name: 'session.report.hitting.batter-performance',
    component: () => import('@/modules/session/SessionChart.vue'),
    meta: {
      icon: 'chartBatterPerformance',
      text: '$vuetify.reports.batterPerformanceTracking',
      filters_key: FilterStores.HIT_TRACKING,
    },
  },
  {
    path: 'post-game',
    name: 'session.report.hitting.post-game',
    component: () => import('@/modules/session/views/PostGame.vue'),
    meta: {
      icon: 'list',
      text: '$vuetify.reports.postGame',
      filters_key: FilterStores.HITTING_POST_GAME,
      require: 'stats',
    },
    props: {
      context: 'hitting',
    }
  },
];

export default [
  {
    path: 'summary',
    name: 'session.summary',
    component: Summary,
  },
  {
    path: 'pitching',
    name: 'session.report.pitching',
    redirect: { name: 'session.report.pitching.summary' },
    component: ReportWithFilters,
    children: PitchingDetailsChildren,
  },
  {
    path: 'hitting',
    name: 'session.report.hitting',
    redirect: { name: 'session.report.hitting.summary' },
    component: ReportWithFilters,
    children: HittingDetailsChildren,
  },
  {
    path: 'table/:action?/:vid(\\d+)?/:share?',
    name: 'session.table',
    component: DataTable,
    meta: {
      // scrollContainer: '.fixedTable .v-data-table__wrapper',
    },
  },
];
