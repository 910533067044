import { Enums } from '@flightscope/baseball-stats';
import { Session, LineupTag } from '@/models/orm/Hierarchy';
import {  ELineupType } from '@/enums/dto';
import { EReportContext } from '@/enums/reports';

/*
 * Based mostly on MLB box score, with some commented out notes from box score wikipedia
 * @see https://en.wikipedia.org/wiki/Box_score_(baseball)
 *
 * Some of the params are unavailable at this moment
 */

/**
 * @typedef {{
 * key: string;
 * aggregator: string;
 * name: string;
 * short: string;
 * type: string;
 * description: string;
 * precision: number;
 * }} StatEnum
 */

// const Fielding = [
//   DP: 2. Bream-Belliard-Bream, Blauser-Lemke-Bream.
//   E: Hunter (1).
// ];


/** @type {StatEnum[]} */
const Batting = [
  Enums.BattingRankingStats.TwoBaseHits,
  Enums.BattingRankingStats.ThreeBaseHits,
  Enums.BattingRankingStats.HomeRuns,
  // Enums.BattingRankingStats.RunsBattedIn,
  Enums.BattingRankingStats.SacrificeFlies,
  Enums.BattingRankingStats.SacrificeHits,
  Enums.BattingRankingStats.GroundIntoDoublePlays,
  Enums.BattingRankingStats.TotalBases,
  // Enums.BattingRankingStats.HitByPitches,
  // Team LOB
];

/** @type {StatEnum[]} */
const BaseRunning = [
  Enums.BattingRankingStats.CaughtStealing,
  Enums.BattingRankingStats.StolenBases,
];

/** @type {StatEnum[]} */
const Pitching = [
  Enums.PitchingRankingStats.BattersFaced,
  Enums.PitchingRankingStats.WildPitches,
  Enums.PitchingRankingStats.HitBatsmen,
  // WP–Guthrie (1).
  // HBP–Erickson (1,Smith).
  // Inherited Runners - Scored: Guthrie 1-0; Willis 3-1.
];

// const Overall = [
//   U–Ed Montague (NL), Don Denkinger (AL), Harry Wendelstedt (NL), Drew Coble (AL), Rick Reed (AL), Terry Tata (NL).
//   T–3:46.
//   A–55,155.
// ];

export default {
  name: 'BoxScore',

  props: {
    context: {
      type: Object,
      required: true,
    },

    teamType: {
      type: String,
      required: true,
    },

    session: {
      type: Session,
    },

    score: {
      type: Object,
      required: true,
    },
  },

  computed: {
    lineup() {
      return this.teamType === ELineupType.AWAY ? this.session.awayLineup : this.session.homeLineup;
    },

    opponentLineup() {
      return this.teamType === ELineupType.HOME ? this.session.awayLineup : this.session.homeLineup;
    },

    battingContent() {
      return this.prepareContent(Batting);
    },
    baseRunningContent() {
      return this.prepareContent(BaseRunning);
    },
    pitchingContent() {
      return this.prepareContent(Pitching);
    },

    hasContent() {
      switch(this.context.key) {
        case EReportContext.BATTING.key:
          return this.battingContent?.length|| this.baseRunningContent?.length;
        case EReportContext.PITCHING.key:
          return this.pitchingContent?.length;
        default:
          return false;
      }
    },

    scopes() {
      let out = [];
      switch(this.context.key) {
        case EReportContext.BATTING.key:
          if (this.battingContent?.length) {
            out.push({ key: 'batting', title: 'Batting', items: this.battingContent });
          }

          if (this.baseRunningContent?.length) {
            out.push({ key: 'base-running', title: 'Base running', items: this.baseRunningContent });
          }
          break;
        case EReportContext.PITCHING.key:
          if (this.pitchingContent?.length) {
            out.push({  key: 'pitching', items: this.pitchingContent });
          }
          break;
        default:
          // NOOP
      }
      return out;
    },
  },

  methods: {
    prepareContent(arr) {
      const scoreForTeam = this.score[this.lineup.teamID];

      const output = arr.reduce((acc, curr, index) => {
        const scoreNode = scoreForTeam?.[curr.key];

        if (scoreNode?.length) {
          const val = scoreNode.map(({ parameters, scope }) => {
            const value = parameters[0].value;

            const { tagId, opponentId } = scope;

            let player1;
            let player2;

            if(tagId) {
              player1 = this.session.getPlayer(tagId);
            }
            if(opponentId) {
              player2 = this.session.getPlayer(opponentId);
            }

            if (!player1) {
              player1 = new LineupTag({ tagID: tagId });
            }

            switch(curr.key) {
              case Enums.BattingRankingStats.RunsBattedIn.key:
              case Enums.PitchingRankingStats.BattersFaced.key:
              case Enums.BattingRankingStats.SacrificeFlies.key:
              case Enums.BattingRankingStats.SacrificeHits.key:
              case Enums.BattingRankingStats.GroundIntoDoublePlays.key:
              case Enums.PitchingRankingStats.WildPitches.key:
                return `${player1.FormattedName} (${value})`;
              default:
                if (!player2 && opponentId) {
                  player2 = new LineupTag({ tagID: opponentId });
                }
                if (!player2) {
                  return `${player1.FormattedName} (${value})`;
                }
                const line = `${player1.FormattedName} (${value}, ${player2.FormattedName})`;
                return line;
            }

          }).join(', ');

          if (val) {
            acc.push([curr.short, val]);
          }
        }

        return acc;
      }, []);

      return output;
    },
  },
};
