<template>
<v-container fluid class="ma-0 pa-0">
<v-row class="ma-0 pa-0 flex-nowrap">

<v-col cols="auto" class="pa-0 ma-0" :class="$style.col">
<v-autocomplete
    v-model="home"
    :disabled="this.filterIsLoading(this.module)"
    :items="opponentsItems"
    :menu-props="menuProps"
    item-text="displayName"
    item-value="id"
    label="Home Team"
    outlined
    dense
    hide-details
></v-autocomplete>
</v-col>

<v-col cols="auto" class="pa-0 ma-0">
<v-btn
  :disabled="this.filterIsLoading(this.module) || this.showAll"
  :style="this.filterIsLoading(this.module) || this.showAll ? 'opacity: 0.3;' : ''"
  icon
  text
  tile
  @click="switchTeams"
><v-icon>$vuetify.icons.switch</v-icon></v-btn>
</v-col>

<v-col cols="auto" class="pa-0 ma-0" :class="$style.col">
<v-autocomplete
    v-model="away"
    :disabled="this.filterIsLoading(this.module)"
    :items="opponentsItems"
    :menu-props="menuProps"
    item-text="displayName"
    item-value="id"
    label="Away Team"
    outlined
    dense
    hide-details
></v-autocomplete>
</v-col>

<v-col cols="auto" class="pa-0 ma-0">
<v-checkbox
  v-model="showAll"
  :disabled="this.filterIsLoading(this.module)"
  dense
  hide-details
  color="primary"
  class="mt-1 pl-3"
  label="Show all Games"
></v-checkbox>
</v-col>

</v-row>
</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_TEAM } from '../../store/actions/sessions';
import { concatAndDeDuplicate } from '../../utils/helpers';


export default {
  name: 'TeamsFilter',
  props: ['module'],
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
      baseTeam: [{ id: '', displayName: 'All Teams' }],
    };
  },
  computed: {
    ...mapGetters('entities/teams', ['teamsForSeason']),

    ...mapGetters('app', ['filters']),

    ...mapGetters(['filterIsLoading']),

    opponentsItems() {
      return concatAndDeDuplicate(this.baseTeam, this.teamsForSeason(this.filters?.season.id));
    },

    isLoading() {
      return this.$store.state.sessions[this.module].filter.team_home;
    },

    home: {
      get() {
        return this.$store.state.sessions[this.module].filter.team_home;
      },
      set(value) {
        this.$store.dispatch(SET_TEAM, { module: this.module, key: '_home', value });
      },
    },

    away: {
      get() {
        return this.$store.state.sessions[this.module].filter.team_away;
      },
      set(value) {
        this.$store.dispatch(SET_TEAM, { module: this.module, key: '_away', value });
      },
    },
    showAll: {
      get() {
        return this.$store.state.sessions[this.module].filter.team_show_all;
      },
      set(value) {
        this.$store.dispatch(SET_TEAM, { module: this.module, key: '_show_all', value });
      },
    },
  },

  methods: {
    switchTeams() {
      [this.home, this.away] = [this.away, this.home];
    },
  },
};
</script>

<style lang="scss" module>
.col {
  max-width: 15em;
}
</style>
