// sessions list
import SessionsFilter from '@/components/filters/SessionsFilter.vue';
import SessionsList from '@/components/SessionsList.vue';

export default [
  {
    path: 'games',
    name: 'games',
    components: {
      default: SessionsList,
      filters: SessionsFilter,
    },
    props: {
      default: {
        tableHeaders: [
          { value: 'dataVerified', },
          { text: 'Date', value: 'createDate' },
          { text: 'Game', value: 'displayName' },
          { text: 'Away Team', value: 'AwayTeam' },
          { text: 'R', value: 'lineScore.Away.R' },
          { text: 'H', value: 'lineScore.Away.H' },
          { text: 'E', value: 'lineScore.Away.E' },
          { text: 'Home Team', value: 'HomeTeam' },
          { text: 'R', value: 'lineScore.Home.R' },
          { text: 'H', value: 'lineScore.Home.H' },
          { text: 'E', value: 'lineScore.Home.E' },
        ],
        module: 'games',
      },
      filters: (route) => ({ module: route.name }),
    },
  },
  {
    path: 'bullpen',
    name: 'pitchingPractices',
    components: {
      default: SessionsList,
      filters: SessionsFilter,
    },
    props: {
      default: {
        tableHeaders: [
          { value: 'dataVerified', },
          { text: 'Start', value: 'createDate' },
          { text: 'Session Name', value: 'displayName' },
          { text: 'Team', value: 'HomeTeam' },
          { text: 'Attendants', value: 'lineupTeamsOrganizationCodes[0]' },
          { text: 'No. of Pitches', value: 'resultTypes.P' },
          { text: 'End', value: 'endDate' },
        ],
        module: 'pitching',
      },
      filters: {
        module: 'pitching',
      },
    },
  },
  {
    path: 'pitching-practices',
    redirect: { name: 'pitchingPractices' },
  },
  {
    path: 'batting-practices',
    name: 'hittingPractices',
    components: {
      default: SessionsList,
      filters: SessionsFilter,
    },
    props: {
      default: {
        tableHeaders: [
          { value: 'dataVerified', },
          { text: 'Start', value: 'createDate' },
          { text: 'Session Name', value: 'displayName' },
          { text: 'Team', value: 'HomeTeam' },
          { text: 'Attendants', value: 'lineupTeamsOrganizationCodes[0]' },
          { text: 'No. of Hits', value: 'resultTypes.H'},
          { text: 'End', value: 'endDate' },
        ],
        module: 'hitting',
      },
      filters: {
        module: 'hitting',
      },
    },
  },
  {
    path: 'hitting-practices',
    redirect: { name: 'hittingPractices' },
  },
  {
    path: 'scrimmage',
    name: 'gamePractices',
    components: {
      default: SessionsList,
      filters: SessionsFilter,
    },
    props: {
      default: {
        tableHeaders: [
          { value: 'dataVerified', },
          { text: 'Date', value: 'createDate' },
          { text: 'Game', value: 'displayName' },
          { text: 'Away Team', value: 'AwayTeam' },
          { text: 'R', value: 'lineScore.Away.R' },
          { text: 'H', value: 'lineScore.Away.H' },
          { text: 'E', value: 'lineScore.Away.E' },
          { text: 'Home Team', value: 'HomeTeam' },
          { text: 'R', value: 'lineScore.Home.R' },
          { text: 'H', value: 'lineScore.Home.H' },
          { text: 'E', value: 'lineScore.Home.E' },
        ],
        module: 'practice',
      },
      filters: {
        module: 'practice',
      },
    },
  },
  {
    path: 'game-practices',
    redirect: { name: 'gamePractices' },
  },

  {
    path: 'live-bp',
    name: 'LiveBP',
    components: {
      default: SessionsList,
      filters: SessionsFilter,
    },
    props: {
      default: {
        tableHeaders: [
          { value: 'dataVerified', },
          { text: 'Start', value: 'createDate' },
          { text: 'Session Name', value: 'displayName' },
          { text: 'Team', value: 'HomeTeam' },
          { text: 'Attendants', value: 'lineupTeamsOrganizationCodes[0]' },
          { text: 'No. of Hits', value: 'resultTypes.H'},
          { text: 'End', value: 'endDate' },
        ],
        module: 'LiveBP',
      },
      filters: {
        module: 'LiveBP',
      },
    },
  },
];
