<template>
  <v-row align="center" class="d-flex mb-2">
    <v-col cols="auto" class="d-flex align-center flex-grow-1">
      <v-divider></v-divider>

      <team-logo-url
        v-if="displayLineups && awayLineup && awayLineup.team"
        :url="awayLineup.team.logo"
        :name="awayLineup.team.displayName"
        :short-code="awayLineup.team.ShortCode"
        class="px-2"
        displayName
      ></team-logo-url>

      <v-divider></v-divider>
    </v-col>

    <v-col cols="auto">
      <div class="text-center headline px-3">{{ context.label }}</div>
    </v-col>

    <v-col cols="auto" class="d-flex align-center flex-grow-1">
      <v-divider></v-divider>

      <team-logo-url
        v-if="displayLineups && homeLineup && homeLineup.team"
        :url="homeLineup.team.logo"
        :name="homeLineup.team.displayName"
        :short-code="homeLineup.team.ShortCode"
        class="px-2"
        displayName
      ></team-logo-url>

      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { Lineup } from '@/models/orm/Hierarchy';


import TeamLogoUrl from '@/components/team/TeamLogoUrl.vue';

export default {
  name: 'LineupHeading',

  components: {
    TeamLogoUrl,
  },

  props: {
    homeLineup: {
      type: Lineup,
    },
    awayLineup: {
      type: Lineup,
    },
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    displayLineups() {
      return this.homeLineup?.team && this.awayLineup?.team;
    }
  },
};
</script>

<style>
</style>
