<template>
  <v-card light>
    <v-container class="py-0">
      <v-row align="center" justify="space-between" :class="$style.header">
        <v-col cols="auto" class="flex-nowrap pl-4 py-3">
          <player-avatar :src="playerData.AvatarURL" class="mr-2"></player-avatar>
          <player-profile-link :id="playerData.id" context="batting">
            <strong class="subtitle-1 font-weight-bold">{{ playerData.FormattedNameWithJersey }}</strong>
          </player-profile-link>
        </v-col>
        <v-col cols="auto" class="body-2 pr-4 py-3">
          <strong>Batting:</strong>
          {{ playerData.Bats || '-' }}

          <strong class="pl-4">No.:</strong>
          {{ playerData.Number || playerData.JerseyNumber || '-' }}

          <strong class="pl-4">Height:</strong>
          {{ playerData.Height | convertUnit('HEIGHT_SHORT_FTIN', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: false })}}

          <strong>Weight:</strong>
          {{ playerData.Weight | convertUnit('WEIGHT', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 0 })}}

          <strong>Position:</strong>
          {{ playerData.PrimaryPosition || playerData.Position || '-' }}
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0">

          <profile-batting-summary
            :class="$style.table"
            :expandable="false"
            :player="player"
            :report="report"
            :dynamic-filters="dynamicFilters"
            tile
            flat
            @horizontalScroll="$emit('horizontalScroll',$event)"
          ></profile-batting-summary>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { Enums } from '@flightscope/baseball-stats';
import ProfileBattingSummary from '../../../modules/player-profiles/reports/ProfileBattingSummary.vue';
import { unitSymbolProvider } from '../../../filters/units';
import PlayerAvatar from '../../player/PlayerAvatar.vue';
import defaultPlayerData from '../../../defaults/playerData';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';

export default {
  name: 'PlayerResultsStats',
  components: {
    PlayerAvatar,
    ProfileBattingSummary,
    PlayerProfileLink,
  },
  props: {
    unitsSystem: {
      required: true,
    },
    playerData: {
      type: Object,
      required: true,
      default: defaultPlayerData,
    },
    filters: {
      type: Object,
      default: () => {}
    },
    stats: {
      type: Array,
      required: true,
      default() {
        return {
          PlateAppearance: {
            // 1, 2 ...
            type: [Number, String],
            required: true,
          },
          FinalPitchId: {
            type: Number,
            required: false,
            default: 0,
          },
          NumberOfPitches: {
            type: Number,
            required: true,
          },
          PitcherId: {
            type: Number,
            required: false,
            default: 0,
          },
          PitcherObj: {
            type: Object,
            required: true,
          },
          PitchThrown: {
            type: String,
            required: true,
          },
          PitchResult: {
            type: String,
            required: true,
          },
          PitchType: {
            // 'FF','FT','SP','FK','FC','SI','SL','CH','CU','SV','SC','CC','UI','RI','DR','FA', 'KN'
            type: String,
            required: true,
            default: 'UI',
          },
          PitchSpeed: {
            type: Number,
            required: true,
          },
          PitchSpin: {
            type: Number,
            required: true,
          },
          StrikeZoneSector: {
            type: String,
            required: true,
          },
          BattedBallType: {
            type: String,
            required: false,
            default: 'UI',
          },
          FinalHitId: {
            type: Number,
            required: false,
            default: 0,
          },
          SwingHandedness: {
            type: String,
            required: false,
            default: 'R',
          },
          // NaN possible
          ExitSpeed: {
            type: Number,
            required: true,
          },
          // NaN possible
          MAXExitSpeed: {
            type: Number,
            required: true,
          },
          // NaN possible
          CarryDistance: {
            type: [Number, String],
            default: '-',
          },
          // NaN possible
          HangTime: {
            type: Number,
            default: NaN,
          },
          // NaN possible
          HitLaunchH: {
            type: Number,
            default: NaN,
          },
          // NaN possible
          HitLaunchV: {
            type: Number,
            default: NaN,
          },
          CarryMax: {
            type: Number,
            default: NaN,
          },
          LandingPoint: {
            type: Array,
            required: false,
            default() {
              return {
                X: {
                  type: Number,
                  required: false,
                  default: NaN,
                },
                Y: {
                  type: Number,
                  required: false,
                  default: NaN,
                },
                Z: {
                  type: Number,
                  required: false,
                  default: NaN,
                },
              };
            },
          },
        };
      },
    },
    summary: {
      type: Object,
      required: true,
      default() {
        return {
          Hits: {
            type: Number,
            default: 0,
          },
          HomeRuns: {
            type: Number,
            default: 0,
          },
          TotalBases: {
            type: Number,
            default: 0,
          },
          GroundBalls: {
            type: Number,
            default: 0,
          },
          Pitches: {
            type: Number,
            default: 0,
          },
          BattedBalls: {
            type: Number,
            default: 0,
          },
          FairBalls: {
            type: Number,
            default: 0,
          },
          LineDrives: {
            type: Number,
            default: 0,
          },
          FlyBalls: {
            type: Number,
            default: 0,
          },
          PopUps: {
            type: Number,
            default: 0,
          },

          // NaN possible for that props
          GroundBallRate: {
            type: [Number, String],
            default: '-',
          },
          LineDriveRate: {
            type: [Number, String],
            default: '-',
          },
          FlyBallRate: {
            type: [Number, String],
            default: '-',
          },
          PopUpRate: {
            type: [Number, String],
            default: '-',
          },
          PitchesPerPa: {
            type: [Number, String],
            default: '-',
          },
        };
      },
    },

    report: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      pitchTypes: Enums.PitchType,
    };
  },
  computed: {
    // TODO - seems to be not used anywhere, not sure if it's pitch or hit ?
    speedUnit() { return unitSymbolProvider('SPEED', this.unitsSystem.system); },
    angleUnit() { return unitSymbolProvider('ANGLE', this.unitsSystem.system); },
    carryDistanceUnit() {
      return unitSymbolProvider(Enums.BattingStats.CarryDistance.type,
        this.unitsSystem.system);
    },
    valueOrDash() {
      return val => (val || '-');
    },
    player() {
      return {
        Id: this.playerData.id,
      };
    },
    dynamicFilters() {
      return {
        ...this.filters,
        batterIds: [this.playerData.id],
      };
    },
  },
  methods: {
    calcTotalFromArray(property) {
      return this.stats
        .map(stat => stat[property])
        .reduce((total, num) => total + num, 0);
    },
    headerText(text, unit) {
      return unit ? `${text} [${unit}]` : text;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  th, td {
    height: 3em;
    color: var(--v-mine-shaft-base);
    text-decoration: none solid rgb(0, 0, 0);
    padding: 0 0.3em;
  }

  thead th {
    opacity: 0.8;
    font-size: 0.75em;
    font-weight: bold;
  }

  tbody td {
    font-size: 0.88em;
  }

  tfoot th {
    font-size: 0.88em;
  }

  caption {
    border-bottom: 1px solid#bdbdbd;
    font-size: 14px;
    color:#717171;
    height: 2em;
  }
}
</style>
