<template>

  <v-tooltip top color="transparent">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        {{ value }}
      </div>
    </template>

    <v-card tile class="validation-tip">
      <v-card-text>
        <h4 v-if="isValidation">{{ $vuetify.lang.t('$vuetify.sanitizer.headers.validation') }}</h4>
        <template v-if="isValidation">
          <p v-for="(note, i) in validationScopeNote" :key="'V'+ i">{{ note }}</p>
        </template>

        <h4 v-if="isSanitization">{{ $vuetify.lang.t('$vuetify.sanitizer.headers.sanitization') }}</h4>
        <template v-if="isSanitization">
          <p v-for="(note, i) in sanitizationScopeNote" :key="'S' + i">{{ note }}</p>
        </template>
      </v-card-text>
    </v-card>

  </v-tooltip>

</template>

<script>
export default {
  name: 'SanitizedCell',

  props: {
    value: {
      type: [String, Object],
      default: '-',
    },
    icon: {
      type: String,
      default: 'mdi-alert-outline',
    },
    isValidation: Boolean,
    isSanitization: Boolean,
    messages: Object,
    messageKey: String,
  },

  computed: {
    validationScopeNote() {
      return this.messages.validation[this.messageKey];
    },

    sanitizationScopeNote() {
      return this.messages.sanitization[this.messageKey];
    },
  },
};
</script>
