<template>
  <v-data-table
    :items-per-page="itemsPerPage"
    hide-default-footer
    hide-default-header
    disable-sort
    :loading="loading"
    :headers="tableHeaders"
    :items="items"
    :class="$style.lineupTable"
    item-key="index"
    :item-class="rowStyle"
  >
    <template v-slot:[`item.player`]="{ item, value }">
      <td>
        <v-tooltip top color="transparent" nudge-left="80" :content-class="$style.playerTooltip">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <!-- <player-avatar v-if="!session.v2" v-model="value.AvatarURL" size="30" class="mr-2"></player-avatar> -->
              <player-profile-link :id="value.tagID" :context="context.key">
                <strong v-if="value">{{ value.FormattedName }}</strong
                >, <span class="font-italic">{{ position(item) }}</span>
              </player-profile-link>
            </div>
          </template>
          <player-tooltip :playerData="value" :class="$style.playerTooltip" :unitsSystem="unitsSystem"></player-tooltip>
        </v-tooltip>
      </td>
    </template>

    <!-- <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" v-if="item.change">
          <pre><code>{{ item.player.subs }}</code></pre>
          <pre><code>{{item.change.type}}</code></pre>
      </td>
    </template> -->

    <template #foot="{ items }">
      <tfoot v-if="headers.length">
        <tr>
          <th :colspan="session.v2 && context.key === 'batting' ? 2 : 1">{{ text.footer.title }}</th>

          <th class="text-center" v-for="(col, i) in headers" :key="i">{{ total[col.key] | value }}</th>
        </tr>
      </tfoot>
    </template>

    <template #header="{ props }">
      <thead>
        <tr>
          <template v-for="(header, i) in props.headers">
            <th-ext v-if="header.desc" :key="i" dfn align="center" :title="header.desc">{{
              $vuetify.lang.t(header.text)
            }}</th-ext>
            <th :key="i" v-else-if="header.text">{{ $vuetify.lang.t(header.text) }}</th>
            <th :key="i" v-else></th>
          </template>
        </tr>
      </thead>
    </template>

    <template v-slot:no-data>
        <no-data padding="32px"></no-data>
      </template>
  </v-data-table>
</template>

<script>
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';
import { Enums, Providers } from '@flightscope/baseball-stats';
import { Session, LineupTag } from '@/models/orm/Hierarchy';
import { EReportContext } from '@/enums/reports';
import { ELineupType } from '@/enums/dto';

import HasPlayers from '@/components/mixins/HasPlayers';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import PlayerTooltip from '@/components/player/PlayerTooltip.vue';
import ThExt from '@/components/ui/tables/ThExt.vue';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';
import { SessionType } from '@/store/actions/api';
import { convertUnit } from '@/filters/units';
const NoData = () => import('@/components/core/NoData.vue');

const messages = {
  headers: {
    title: 'Player',
    PlateAppearances: 'PA',
    NumberOfPitches: 'NP',
    HitsAllowed: 'H',
    StrikeOuts: 'SO',
    HomeRuns: 'HR',
    RunsAllowed: 'R',
    WalksAllowed: 'W',
  },
  footer: {
    title: 'Totals',
  },
};

export default {
  name: 'LineupSingle',

  mixins: [HasPlayers],

  components: {
    PlayerAvatar,
    PlayerTooltip,
    ThExt,
    PlayerProfileLink,
    NoData,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },

    context: {
      type: Object,
      requred: true,
    },

    teamType: {
      type: String,
      required: true,
    },

    stats: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    unitsSystem: {
      requred: true,
    },

    headers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      itemsPerPage: -1,
      text: messages,
      headerDesc: {
        pitching: {
          ...Enums.PitchingRankingStats,
          [Enums.PitchingRankingStats.KZoneRate.key]: {
            ...Enums.PitchingRankingStats.KZoneRate,
            short: 'Zone %'
          },
        },
        batting: {
          ...Enums.BattingRankingStats,
          [Enums.BattingRankingStats.ZContactRate.key]: {
            ...Enums.BattingRankingStats.ZContactRate,
            short: 'Zone Contact %'
          },
          [Enums.BattingRankingStats.ExitSpeedMax.key]: {
            ...Enums.BattingRankingStats.ExitSpeedMax,
            short: 'Top Exit Speed'
          }
        },
      },
    };
  },

  computed: {
    items() {
      return this.order.map(this.orderMapper);
    },

    statsForContext() {
      return this.stats?.[this.context.key];
    },

    rowStyle(item) {
      if (item.lineupPosition) {
        return 'linuep-player';
      }
      if (item.change) {
        return 'substitute-player';
      }
      return '';
    },

    tableHeaders() {
      let output = [{ text: 'Player', value: 'player' }];

      if (this.context.key === EReportContext.BATTING.key) {
        output = [{ value: 'lineupPosition' }, ...output];
      }

      if (this.headers.length) {
        let statsCols = this.headers.map((i) => {
          let ctx = this.context.key;
          let dict = this.headerDesc[ctx];
          let { key } = i;

          let def = Providers.EnumValueProvider.getValue(key, dict, true);

          return {
            text: def.short, value: `stats.${i.key}.value`, desc: def.description, align: 'center'
          };
        });

        output = [...output, ...statsCols];
      }

      return output;
    },

    lineup() {
      return this.teamType === ELineupType.AWAY ? this.session.awayLineup : this.session.homeLineup;
    },

    order() {
      let order;

      switch (this.context.key) {
        case Enums.RankingScopeContext.Batting.key:
          order = this.lineup.battingList;
          break;
        case Enums.RankingScopeContext.Pitching.key:
          const ids = this.lineup.playerIds;

          const allPitcherIds = uniq(this.session.resultsv1.filter(r => r.ResultType == 'P').map(r => r.TagID));

          const pitcherIds = intersection(allPitcherIds, ids);

          order = pitcherIds.map((id) => {
            let player = this.lineup.findPlayer(id);

            if (player)  {
              return player;
            }

            return null;
          }).filter(p => p);
          break;

        default:
          throw Error('Invalid summary scope');
      }

      return order;
    },

    total() {
      let stats = {};
      if (this.statsForContext) {
        stats = this.statsForContext?.perTeam?.[this.lineup.teamID] || this.statsForContext.defaultStats;
      }

      // BBP-1133 - some stats needs conversion
      this.maybeConvert(stats, this.context.key);

      return stats;
    },
  },

  methods: {
    orderMapper(orderTag, index) {
      let tagId;
      let player;
      let change;
      let main;
      let sw;

      if (orderTag instanceof LineupTag) {
        tagId = orderTag.tagID;
        player = orderTag;
        main = orderTag.playerIndex;
        sw = orderTag?.switch;
      } else if (this.context.key === 'batting') {
        tagId = orderTag.enteringPlayer.tagID;
        player = orderTag.enteringPlayer;
        sw = orderTag?.enteringPlayer?.switch;
        change = {
          from: orderTag.exitingPlayer,
          type: orderTag.type,
        };
      } else if (this.context.key === 'pitching') {
        tagId = orderTag.tagID;
        player = orderTag;
      }

      let statsForPLayer = {};

      if (this.statsForContext) {
        if (this.statsForContext?.perPlayer?.[tagId]) {
          statsForPLayer = this.statsForContext.perPlayer[tagId];
        } else {
          statsForPLayer = this.statsForContext.defaultStats;
        }
      }

      // BBP-1133 - some stats needs conversion
      this.maybeConvert(statsForPLayer, this.context.key);

      return {
        index,
        player,
        lineupPosition: main || null,
        stats: statsForPLayer,
        change,
        switch: sw,
      };
    },

    maybeConvert(statsForPLayer, context) {
      let enumDict = null;
      if (context === 'batting') {
          enumDict = Enums.BattingRankingStats;
      }
      else if (context === 'pitching') {
          enumDict = Enums.PitchingRankingStats;
      }
      else {
        return;
      }

      for (const statName in statsForPLayer) {
          const statObject = statsForPLayer[statName];
          if (statObject.value === '' || isNaN(statObject.value)) {
            continue;
          }

          const statDef = Providers.EnumValueProvider.getValue(
            statName, enumDict
          );
          if (
            !statDef
            || !statDef.hasOwnProperty('type')
            || !statDef.type
            || statDef.type === 'NUMBER' // do not convert stats like HITS or SO
          ) {
            continue;
          }

          // convert the unit
          statObject.value = convertUnit(
            statObject.value,
            statDef.type,
            this.unitsSystem.system
          );
        }
    },

    position(item) {
      let pos;

      if (item.change) {
        pos = item.change.from.PrimaryPosition;
      }

      if (item?.switch) {
        return [pos ?? item.player.PrimaryPosition, ...item.switch].join('-');
      }

      return pos ?? item.player.PrimaryPosition;
    },

    /**
     * @param {String} context
     * @param {Lineup} lineup
     * @param { perPlayer: Object, perTeam: Object } stats
     */
    mergeWithStats(context, lineup, stats) {
      this.$log.debug('merge');
      let order = [];

      const defaultStats = this.headers[context].reduce((acc, curr) => {
        acc[curr.key] = null;
        return acc;
      }, {});

      switch (context) {
        case Enums.RankingScopeContext.Batting.key:
          order = lineup.battingList;
          break;
        case Enums.RankingScopeContext.Pitching.key:
          order = lineup.pitchingList;
          break;
        default:
          throw Error('Invalid summary scope');
      }

      const items = order.map((orderTag, index) => {
        let tagId;
        let player;
        let change;
        let main;

        if (orderTag instanceof LineupTag) {
          tagId = orderTag.tagID;
          player = orderTag;
          main = orderTag.playerIndex;
        } else if (context === 'batting') {
          tagId = orderTag.enteringPlayer.tagID;
          player = orderTag.enteringPlayer;
          change = {
            from: orderTag.exitingPlayer,
            type: orderTag.type,
          };
        } else if (context === 'pitching') {
          tagId = orderTag.tagId;
          player = orderTag;
        }

        let statsForPLayer;

        if (stats && stats?.perPlayer?.[tagId]) {
          statsForPLayer = stats.perPlayer[tagId];
        }
        if (!stats) {
          statsForPLayer = defaultStats;
        }

        // BBP-1133 - some stats needs conversion
        this.maybeConvert(statsForPLayer, this.context.key);

        return {
          index,
          player,
          lineupPosition: main || null,
          stats: statsForPLayer,
          change,
        };
      });

      const total = stats?.perTeam?.[lineup.teamID] || defaultStats;

      let out = {
        items,
        total,
      };

      return out;
    },

    value(obj) {
      return get(obj, 'value');
    },
  },

  filters: {
    value(val) {
      if (!val) return '';
      val = get(val, 'value');
      return val ?? '-';
    },
  },
};
</script>

<style lang="scss" module>
.lineupTable {
  thead th {
    font-size: 12px;
    height: 46px;
  }
  tbody td {
    font-size: 14px;
    height: 40px;
  }
  tbody tr > td:nth-of-type(1) {
    width: 50px;
  }
  tfoot td {
    font-size: 14px;
    height: 40px;
  }
}

.playerTooltip {
  opacity: 1 !important;
}
</style>
