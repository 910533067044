import goTo from 'vuetify/lib/services/goto';

function $(el) {
  if (typeof el === 'string') {
    return document.querySelector(el);
  } else if (el && el._isVue) {
    return el.$el;
  } else if (el instanceof HTMLElement) {
    return el;
  } else {
    return null;
  }
}

function type(el) {
  return el == null ? el : el.constructor.name;
}

export function getContainer(container) {
  const el = $(container);
  if (el) return el;
  throw typeof container === 'string'
    ? new Error(`Container element "${container}" not found.`)
    : new TypeError(`Container must be a Selector/HTMLElement/VueComponent, received ${type(container)} instead.`);
}

export function getOffset(target) {
  if (typeof target === 'number') {
    return target;
  }

  let el = $(target);

  if (!el) {
    throw typeof target === 'string'
      ? new Error(`Target element "${target}" not found.`)
      : new TypeError(`Target must be a Number/Selector/HTMLElement/VueComponent, received ${type(target)} instead.`);
  }

  let totalOffset = 0;

  while (el) {
    totalOffset += el.offsetTop;
    el = el.offsetParent;
  }

  return totalOffset;
}

// scrollBehavior:
// - only available in html5 history mode
export function scrollBehavior(_to, _from, _savedPosition) {
  let position;
  let options = {};

  if (_to.hash) {
    position = _to.hash;
  } else if (_savedPosition) {
    position = _savedPosition.y;
  } else {
    position = 0;
  }

  if (_to.meta && _to.meta.scrollContainer) {
    options.container = _to.meta.scrollContainer;
    options.appOffset = false;
  }

  return goTo(position, options);
}

export function scrollTo(_target, _settings = {}) {
  const settings = {
    container: document.scrollingElement || document.body || document.documentElement,
    duration: 500,
    offset: 0,
    easing: 'easeInOutCubic',
    ..._settings,
  };

  const position = { x: 0, y: 0 };

  const container = getContainer(settings.container);

  let targetLocation;

  if (typeof _target === 'number') {
    targetLocation = getOffset(_target) - settings.offset;
  } else {
    targetLocation = getOffset(_target) - getOffset(container) - settings.offset;
  }

  const clientHeight = container === document.body ? document.documentElement.clientHeight : container.clientHeight;
  const scrollHeight = container === document.body ? document.documentElement.scrollHeight : container.scrollHeight;

  if(targetLocation < scrollHeight) {
    container.scrollTop = targetLocation;
  }
}
