import { Enums } from '@flightscope/baseball-stats';
import UniqueParamListProvider from '@/providers/UniqueParamListProvider';
import FilterStores from '@/enums/StoreKeys';
import { EPitchSet, EHandedness, EPitchResult, EPOuts, ERunnersOn } from '@/filters/enums';
import ResultFilter from '../../filters/ResultFilter';
import { captureException } from '@sentry/vue';

export default {
  data() {
    return {
      filterRanges: {},
      filterOptions: {},
      hasRange: false,
    };
  },

  methods: {
    getUniqueParameters(_options = {}, { hitParam, pitchParam }) {
      this.hasRange = false;
      try {
        let filterRanges;
        if (FilterStores.HIT_TRACKING == this.filters_key) {
          filterRanges = UniqueParamListProvider[this.filters_key](this.session.resultsv1, hitParam, undefined, _options);
        } else if (FilterStores.PITCH_TRACKING == this.filters_key) {
          filterRanges = UniqueParamListProvider[this.filters_key](this.session.resultsv1, pitchParam, undefined, _options);
        } else if (FilterStores.DATA_TABLE == this.filters_key) {
          filterRanges = UniqueParamListProvider[this.filters_key](this.session.resultsv1, _options);
        } else {
          filterRanges = UniqueParamListProvider[this.filters_key](this.session.resultsv1, undefined, _options);
        }

        if (typeof filterRanges === 'object') {
          let mapped = Object.fromEntries(filterRanges);
          this.filterRanges = mapped;
        }
      } catch (e) {
        // Probably not implemented view.
        captureException(e);
        this.$log.error(e);
      }

      this.hasRange = true;
    },

    getPossibleParameters(filters) {
      let options = {};
      // used for hit tracking chart
      let hitParam = Enums.HitData.ExitSpeed.key;
      let pitchParam = Enums.PitchData.Speed.key;


      this.requiredFilters.forEach((item) => {
        if (typeof item === 'string') {
          if (filters[item]) {
            switch (item) {
              case 'pitcher':
                options.pitcherId = filters[item];
                break;
              case 'batter':
                options.batterId = filters[item];
                break;
              case 'hitParameter':
                hitParam = filters[item];
                break;
              case 'pitchParameter':
                pitchParam = filters[item];
                break;
              default:
                // NOOP
            }
          }
        } else {
          let { key, group } = item;
          // TODO: fix possible key duplication when different groups
          switch (key) {
            case 'pitcher':
              options.pitcherId = filters[group][key];
              break;
            case 'batter':
              options.batterId = filters[group][key];
              break;
            case 'hitParameter':
              hitParam = filters[group][key];
              break;
            case 'pitchParameter':
              pitchParam = filters[group][key];
              break;
            default:
              // NOOP
          }
        }
      });
      this.getUniqueParameters(options, { hitParam, pitchParam });
    },

    getNonFilteredOptions(_filterType) {
      let vm = this;
      switch (_filterType) {
        case 'pitcher-handedness': {
          const phProp = Enums.PitchData.Handedness.key;
          return EHandedness;
          }
        case 'batter-handedness': {
          const bhProp = Enums.PitchData.SwingHandedness.key;
          return EHandedness;
          }
        case 'pitch-set':{
          const psProp = Enums.PitchData.Mechanics.key;
          return EPitchSet;
          }
        case ResultFilter.ScoringOuts.key: {
          const outsProp = Enums.PitchData.BeforePlayOuts.key;
          return EPOuts;
        }
        case ResultFilter.ScoringRunnersOn.key:{
          const runnersProp = Enums.PitchData.BeforePlayRunnersOn.key;
          return ERunnersOn;
          }
        default:
          return [];
      }
    },

    getFilteredOptions(_filterType) {
      if (!this.hasRange) {
        return [];
      }

      let vm = this;
      switch (_filterType) {
        case 'pitcher-handedness': {
          const phProp = Enums.PitchData.Handedness.key;
          return EHandedness
            .filter(
              item => item.value === '' || (vm.filterRanges.hasOwnProperty(phProp) && vm.filterRanges[phProp].includes(item.value))
            );
          }
        case 'batter-handedness': {
          const bhProp = Enums.PitchData.SwingHandedness.key;
          return EHandedness
            .filter(
              item => item.value === '' || (vm.filterRanges.hasOwnProperty(bhProp) && vm.filterRanges[bhProp].includes(item.value))
            );
          }
        case 'pitch-set':{
          const psProp = Enums.PitchData.Mechanics.key;
          return EPitchSet
            .filter(
              item => item.value === '' || (vm.filterRanges.hasOwnProperty(psProp) && vm.filterRanges[psProp].includes(item.value))
            );
          }
        case ResultFilter.ScoringOuts.key: {
          const outsProp = Enums.PitchData.BeforePlayOuts.key;
          return EPOuts
            .filter(
              item => item.value === '' || (vm.filterRanges.hasOwnProperty(outsProp) && vm.filterRanges[outsProp].includes(item.value))
            );
        }
        case ResultFilter.ScoringRunnersOn.key: {
          const runnersProp = Enums.PitchData.BeforePlayRunnersOn.key;
          return ERunnersOn
            .filter(
              item => item.value === '' || (vm.filterRanges.hasOwnProperty(runnersProp) && vm.filterRanges[runnersProp].includes(item.value))
            );
          }
        default:
          return [];
      }
    },

    getPossibleOptions(selectionType) {
      switch (selectionType) {
        case 'pitch-result': {
          if (!this.hasRange) {
            return [];
          }
          const prProp = Enums.PitchData.Result.key;
          if (this.filterRanges.hasOwnProperty(prProp)) {
            return this.filterRanges[prProp];
          }
          return [];
        }

        case 'pitch-type': {
          if (!this.hasRange) {
            return [];
          }
          const ptProp = Enums.PitchData.Classification.key;
          if (this.filterRanges.hasOwnProperty(ptProp)) {
            return this.filterRanges[ptProp];
          }
          return [];
        }

        case 'scoring-counts':
        case 'store-scoring-counts': {
          if (!this.hasRange) {
            return {};
          }
          let possibleGroups = {};

          if (this.filterRanges.hasOwnProperty(Enums.PitchData.BeforePlayBalls.key)) {
            possibleGroups.balls = this.filterRanges[Enums.PitchData.BeforePlayBalls.key];
          }

          if (this.filterRanges.hasOwnProperty(Enums.PitchData.BeforePlayStrikes.key)) {
            possibleGroups.strikes = this.filterRanges[Enums.PitchData.BeforePlayStrikes.key];
          }

          return possibleGroups;
        }

        default:
          // NOOP
      }
      return [];
    },

    watchDataSet(isSet) {
      if (isSet && this.sessionAvailable) {
        this.getPossibleParameters(this.storeFilters);
      }
    },
  },
};
