<template>
  <v-select
    v-model="place"
    :items="availablePlaces"
    dense
    hide-details
    offset-y
    label="Game Place"
    outlined></v-select>
</template>

<script>
import { SET_PLACE } from '../../store/actions/sessions';

export default {
  name: 'GamePlaceFilter',
  props: ['module'],
  data() {
    return {
      availablePlaces: [
        { text: 'All Games', value: '' },
        { text: 'Away Games', value: 'away' },
        { text: 'Home Games', value: 'home' },
      ],
    };
  },
  computed: {
    place: {
      get() {
        return this.$store.state.sessions[this.module].filter.place;
      },
      set(value) {
        this.$store.dispatch(SET_PLACE, { module: this.module, value });
      },
    },
  },
};
</script>

<style lang="scss" module>

</style>
