<script>
import { mapGetters } from 'vuex';
import { SET_ATTENDANTS } from '../../store/actions/sessions';
import { ROSTER_REQUEST } from '../../store/actions/roster';
import { concatAndDeDuplicate } from '../../utils/helpers';
import playerNameFilter from '../../filters/playerNameFilter';
import { captureException } from '../../plugins/sentry';

export default {
  name: 'AttendantsFilter',
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
      noFilter: [{ value: [], text: 'No filter' }],
    };
  },
  computed: {
    ...mapGetters(['rosterByTeamId']),
    roster() {
      return (this.teamId > 0) ? this.rosterByTeamId(this.teamId) || [] : [];
    },
    allItems() {
      return concatAndDeDuplicate(this.noFilter, this.mapRosterToOptions(this.roster));
    },
    team: {
      get() {
        return this.$store.state.sessions[this.module].filter.attendants;
      },
      set(value) {
        this.$store.dispatch(SET_ATTENDANTS, { module: this.module, value });
      },
    },
  },
  mounted() {
    this.fetchRoster();
  },
  methods: {
    fetchRoster() {
      if (this.teamId > 0) { // && this.roster == []
        this.$store.dispatch(ROSTER_REQUEST, this.teamId)
          .catch((err) => {
            this.$log.warn(`attendants Team roster ${this.teamId} data promise has been rejected`);
            this.$log.debug(err);
            captureException(err);
          });
        this.$log.debug(`attendants Team roster ${this.teamId} data requested.`);
      } else {
        this.$log.debug('no attendants filter');
      }
    },
    mapRosterToOptions(roster) {
      let homeTeamPlayers = []; // eslint-disable-line prefer-const

      // TODO sort by position
      let homeTeamHeader = [{ header: 'Position ...' }]; // eslint-disable-line prefer-const

      for (let i = 0; i < roster.length; i++) {
        const tagId = parseInt(roster[i].TagID, 10);
        if (tagId < 1) {
          this.$log.warn(`Unable to find playerTag with id ${roster[i].TagID}`);
        } else {
          const itemName = playerNameFilter(process.env.VUE_APP_PLAYER_NAME_FORMAT, roster[i].Tag.Parameters);
          homeTeamPlayers.push({
            text: itemName,
            value: parseInt(tagId, 10),
          });
        }
      }

      let options = [];
      if (homeTeamPlayers.length) {
        options = options.concat(homeTeamHeader, homeTeamPlayers);
      }

      return options;
    },
  },
};
</script>

<template>
  <v-select
    v-model="team"
    :items="allItems"
    :menu-props="menuProps"
    item-text="text"
    item-value="value"
    label="Attendants"
    dense
    hide-details
    outlined
    multiple
  ></v-select>
</template>
