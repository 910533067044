import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import { measurementSystem } from '@flightscope/units-converter';
import { captureException } from '@sentry/vue';

export default {
  props: {
    item: Object,
    showAnomalies: Boolean,
  },
  computed: {
    // TODO:Add proper logic
    anomalies() {
      try {
        return this.item.anomalySrc.FailedValidations;
      } catch (e) {
        captureException(e);
        return [];
      }
    },

    sanitizations() {
      try {
        return this.item.anomalySrc.Sanitization;
      } catch (e) {
        captureException(e);
        return [];
      }
    },

    scopes() {
      return {
        validation: this.validationScopes,
        sanitization: this.sanitizationScopes,
        invalidated: this.invalidatedCells,
      };
    },

    messages() {
      return {
        validation: this.validationMessages,
        sanitization: this.sanitizationMessages,
      };
    },

    validationScopes() {
      if (!this.anomalies.length) return [];
      return uniq(this.anomalies.filter(a => a.validationScope.length).map(a => a.validationScope.join('.')));
    },

    sanitizationScopes() {
      if (!this.sanitizations.length) return [];
      return uniq(this.sanitizations.filter(a => a.sanitizationScope.length).map(a => a.sanitizationScope.join('.')));
    },

    invalidatedCells() {
      return uniq(flatten(this.anomalies.filter(a => a.invalidationScope.length).map(a => a.invalidationScope.map(s => s.join('.')))));
    },

    validationMessages() {
      if (!this.anomalies.length) return {};
      let messages = this.anomalies
        .filter((a) => a.validationScope.length)
        .map((v) => ({ key: v.validationScope.join('.'), message: v.description }));
      return mapValues(
        groupBy(messages, (message) => message.key),
        (mlist) => uniq(mlist.map((m) => m.message)),
      );
    },

    sanitizationMessages() {
      if (!this.sanitizations.length) return {};
      let messages = this.sanitizations
        .filter((a) => a.sanitizationScope.length)
        .map((v) => ({ key: v.sanitizationScope.join('.'), message: v.description }));
      return mapValues(
        groupBy(messages, (message) => message.key),
        (mlist) => uniq(mlist.map((m) => m.message)),
      );
    },

    canExpand() {
      return this.item.hasAnomalies && this.showAnomalies;
    },
  },
};
