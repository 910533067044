<template>
  <time :datetime="utcTimeString" :title="utcTimeString">{{ time | utcToLocal(format)}}</time>
</template>

<script>
import { DATE_FMT } from '@/utils/date_n_time';

export default {
  name: 'CoreTime',
  props: {
    time: {
      type: String,
      required: true,
    },
    format: {
      type: [String,Object],
      default: DATE_FMT.DATETIME_SECONDS,
    },
  },
  computed: {
    utcTimeString() {
      return `${this.time}Z`;
    },
  },
};
</script>
