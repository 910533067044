<template>
  <v-chip :color="matchResultColor" v-bind="$attrs" v-on="$listeners">
    {{ matchResultText }}
  </v-chip>
</template>
<script>
export default {
  name: 'TeamResultChip',
  props: {
    homeTeamId: {
      type: Number,
      required: true,
    },
    awayTeamId: {
      type: Number,
      required: true,
    },
    fromPerspectiveOfTeamId: {
      type: Number,
      required: true,
    },
    homeTeamRuns: Number,
    awayTeamRuns: Number,
    colorLost: {
      type: String,
      default: 'game_result_lost',
    },
    colorWon: {
      type: String,
      default: 'game_result_won',
    },
    colorTied: {
      type: String,
      default: 'game_result_tied',
    },
  },
  computed: {
    isScoreSet() {
      return this.awayTeamRuns !== null && !isNaN(this.awayTeamRuns)
             && this.homeTeamRuns !== null && !isNaN(this.homeTeamRuns);
    },
    isWon() {
      if (this.isScoreSet === false) {
        return false;
      }

      if (this.fromPerspectiveOfTeamId === this.homeTeamId) {
        return this.homeTeamRuns > this.awayTeamRuns;
      }
      if (this.fromPerspectiveOfTeamId === this.awayTeamId) {
        return this.homeTeamRuns < this.awayTeamRuns;
      }

      return false;
    },
    isLost() {
      if (this.isScoreSet === false) {
        return false;
      }

      if (this.fromPerspectiveOfTeamId === this.homeTeamId) {
        return this.homeTeamRuns < this.awayTeamRuns;
      }
      if (this.fromPerspectiveOfTeamId === this.awayTeamId) {
        return this.homeTeamRuns > this.awayTeamRuns;
      }

      return false;
    },
    isTied() {
      if (this.isScoreSet === false) {
        return false;
      }

      return !this.isWon && !this.isLost;
    },
    matchResultText() {
      let result = '-';

      if (this.isWon) {
        result = 'Win';
      } else if (this.isLost) {
        result = 'Lost';
      } else if (this.isTied) {
        result = 'Tie';
      } else {
        return result;
      }

      return `${result} ${this.awayTeamRuns}-${this.homeTeamRuns}`;
    },
    matchResultColor() {
      let color = '';

      if (this.isWon) {
        color = this.colorWon;
      } else if (this.isLost) {
        color = this.colorLost;
      } else if (this.isTied) {
        color = this.colorTied;
      }

      return color;
    },
  },
};
</script>
