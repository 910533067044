<template>
<v-dialog eager v-model="openPopup" :persistent="loading" max-width="377px">
<v-card class="session-edit-card">
  <v-card-title class="px-4">
    {{ $vuetify.lang.t('Change data status') }}
    <v-spacer></v-spacer>
    <v-icon :disabled="loading" @click.stop="openPopup = false">mdi-close</v-icon>
  </v-card-title>


  <v-card-text class="px-4">
  <v-alert v-if="message" :type="alertType">{{ $vuetify.lang.t(message) }}</v-alert>

  <v-checkbox
    v-model="verified"
    class="edit-checkbox"
    hide-details
    :disabled="!session || loading"
    :false-value="false"
    :true-value="true"
    :label="$vuetify.lang.t('The collected data is correct and suitable for further analysis')"
  >
  </v-checkbox>
  </v-card-text>


  <v-card-actions><v-spacer></v-spacer><v-btn
    :loading="loading"
    :disabled="!session || loading"
    color="primary"
    @click="save"
  >{{ $vuetify.lang.t('Save')}}</v-btn></v-card-actions>
</v-card>

</v-dialog>
</template>

<script>
import { Session } from '@/models/orm/Hierarchy';
import TimeoutingComponent from '@/components/ui/TimeoutingComponent.vue';

export default {
  name: 'SessionEditDialog',

  components: {
    TimeoutingComponent,
  },

  inject: ['repo'],

  props: {
    value: Boolean,

    session: Session,

    refreshSession: Function,

    SessionRepo: {
      default() {
        return this.repo.get('sessions');
      },
    },
  },

  data() {
    return {
      valid: false,

      verified: this.session?.dataVerified,

      loading: false,

      disabled: false,

      message: '',

      alertType: '',
    };
  },

  computed: {
    openPopup: {
      get() { return this.value; },
      set(val) { this.$emit('input', val)}
    },
  },

  methods: {
    async save() {
      this.loading = true;
      this.message = '';
      this.alertType = '';

      const response = await this.SessionRepo.setVerifiedFlag(this.session.id, this.verified);

      if (response){
        this.alertType = 'success';
        this.message = `Session data was set as ${this.verified?'':'not '}verified`;

        if (this.refreshSession) {
          this.refreshSession();
        }
      }

      this.loading = false;
    },
  },

}
</script>

<style scoped>
.session-edit-card::v-deep .v-card__title {
  font-size: 1.375rem;
}
.edit-checkbox::v-deep .v-label {
  font-size: .875rem !important;
}
</style>
