import CoreHeader from '@/components/core/CoreHeader.vue';
import CoreDrawer from '@/components/core/CoreDrawer.vue';
import SessionsView from '@/views/SessionsView.vue';
import SingleSession from '@/modules/session/views/SingleSession.vue';
import { sessionsListSubroutes, singleSessionSubroutes } from './subroutes';
import { ability, AclEntities, AclPermissions, session } from '../../../services/ability';


function getPropsFromRoute(route) {
  const props = { ...route.params };
  props.id = +props.id
  return props
}

const can = () => ability.can(AclPermissions.read, AclEntities.Session);

export default [
  {
    path: '/sessions',
    name: 'sessions',
    redirect: { name: 'games' },
    meta: { requiresAuth: true },
    components: {
      header: CoreHeader,
      drawer: CoreDrawer,
      default: SessionsView,
    },
    children: sessionsListSubroutes,
    beforeEnter: (to, from, next) => {
      if (can()) {
        next();
      } else {
        next({ path: process.env.VUE_APP_DEFAULT_REDIRECT });
      }
    },
  },
  {
    path: '/session/:type(\\w+)/:id(\\d+)',
    name: 'session',
    redirect: { name: 'session.summary' },
    meta: { requiresAuth: true },
    components: {
      header: CoreHeader,
      drawer: CoreDrawer,
      default: SingleSession,
    },
    children: singleSessionSubroutes,
    props: {
      default: getPropsFromRoute,
    },
    beforeEnter: (to, from, next) => {
      if (can()) {
        next();
      } else {
        next({ path: process.env.VUE_APP_DEFAULT_REDIRECT });
      }
    },
  },
];
