<script>
import Wrapper from '../components/ui/Wrapper.vue';

export default {
  metaInfo: {
    title: 'Sessions List',
  },
  components: {
    Wrapper,
  },
};
</script>

<template>
  <v-container
    class="sessions-view"
    :align="'start'"
    color="#f5f5f5"
    fluid>
    <v-row>
      <wrapper>
        <router-view class="view filters-view" name="filters"/>
      </wrapper>
    </v-row>
    <v-row>
      <wrapper>
      <router-view class="view scrolled-max-h sessions-list"/>
      </wrapper>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.sessions-view {
  background-color: #f5f5f5;
  --max-h: calc(100vh - 60px );
}
.sessions-list {
  // header - paddings - filters
  --max-h: calc(100vh - 60px - 72px - 16px - 16px);
}
</style>
