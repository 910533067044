import { listUniqueParametersForPitchingSummary } from '@flightscope/baseball-stats/src/providers/PitchingSummaryDataProvider';
import { listUniqueParametersForReleaseChart } from '@flightscope/baseball-stats/src/providers/ReleaseChartsSeriesProvider';
import { listUniqueParametersForLocationChart } from '@flightscope/baseball-stats/src/providers/LocationChartSeriesProvider';
import { listUniqueParametersForExtensionChart } from '@flightscope/baseball-stats/src/providers/ExtensionChartSeriesProvider';
import { listUniqueParametersForMovementChart } from '@flightscope/baseball-stats/src/providers/MovementChartSeriesProvider';
import { listUniqueParametersForPitchTrackingChart } from '@flightscope/baseball-stats/src/providers/PitchTrackingChartSeriesProvider';
import { listUniqueParametersForHittingSummary } from '@flightscope/baseball-stats/src/providers/HittingSummaryDataProvider';
import { listUniqueParametersForStrikeZoneChart } from '@flightscope/baseball-stats/src/providers/StrikeZoneChartSeriesProvider';
import { listUniqueParametersForSprayChart } from '@flightscope/baseball-stats/src/providers/SprayChartSeriesProvider';
import { listUniqueParametersForHitTrackingChart } from '@flightscope/baseball-stats/src/providers/HitTrackingChartSeriesProvider';
import { listUniqueParametersForDataTable } from '@flightscope/baseball-stats/src/providers/DataTableDataProvider';
import { listUniqueParametersForContactPointChart } from '@flightscope/baseball-stats/src/providers/ContactPointSeriesProvider';

import FilterStores from '@/enums/StoreKeys';

export default {
  [FilterStores.PITCHING_SUMMARY]: listUniqueParametersForPitchingSummary,
  [FilterStores.PITCHING_POST_GAME]: listUniqueParametersForPitchingSummary,
  [FilterStores.RELEASE_POINT]: listUniqueParametersForReleaseChart,
  [FilterStores.LOCATION_CHART]: listUniqueParametersForLocationChart,
  [FilterStores.RELEASE_EXTENSION]: listUniqueParametersForExtensionChart,
  [FilterStores.PITCH_MOVEMENT]: listUniqueParametersForMovementChart,
  [FilterStores.PITCH_TRACKING]: listUniqueParametersForPitchTrackingChart,

  [FilterStores.HITTING_SUMMARY]: listUniqueParametersForHittingSummary,
  [FilterStores.HITTING_POST_GAME]: listUniqueParametersForHittingSummary,
  [FilterStores.STRIKEZONE_CHART]: listUniqueParametersForStrikeZoneChart,
  [FilterStores.HIT_SPRAY]: listUniqueParametersForSprayChart,
  [FilterStores.CONTACT_POINT]: listUniqueParametersForContactPointChart,
  [FilterStores.HIT_TRACKING]: listUniqueParametersForHitTrackingChart,

  [FilterStores.DATA_TABLE]: listUniqueParametersForDataTable,
};
