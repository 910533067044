<script>
import { mapGetters } from 'vuex';
import HasTeams from '@/components/mixins/HasTeams';
import DateRangeFilter from './DateRangeFilter.vue';
import GamePlaceFilter from './GamePlaceFilter.vue';
import OpponentFilter from './OpponentFilter.vue';
import TeamSelectorFilter from './TeamSelectorFilter.vue';
import TeamsFilter from './TeamsFilter.vue';
import AttendantsFilter from './AttendantsFilter.vue';

export default {
  name: 'SessionsFilter',

  mixins: [HasTeams],

  props: ['module'],
  components: {
    DateRangeFilter,
    GamePlaceFilter,
    OpponentFilter,
    TeamsFilter,
    TeamSelectorFilter,
    AttendantsFilter,
  },
  data() {
    return {
      store: this.module || 'games',
    };
  },
  computed: {
    ...mapGetters('entities/teams', ['allEventTeamIDs']),
    ...mapGetters([
      'isLeagueManagerForCurrentSeason', 'isProfileLoaded', 'userTeamIDs', 'allTeamsLoaded',
    ]),
    moduleIsGame() {
      return this.module === 'games';
    },
    moduleIsPractice() {
      return this.module === 'pitching' || this.module === 'hitting';
    },
    availableTeamIDs() {
      return (this.isLeagueManagerForCurrentSeason) ? this.allEventTeamIDs : this.userTeamIDs;
    },
    availableTeams() {
      return this.allTeamsLoaded ? this.availableTeamIDs.map(teamID => this.TeamRepo.getTeam(teamID)) : [];
    },
    selectedTeamId() {
      return this.$store.state.sessions[this.module].filter.team;
    },
  },
};
</script>

<template>
  <v-form ref="filterform" v-if="isProfileLoaded">
    <v-row class="flex-nowrap">
      <v-col cols="auto" :class="$style.dateRangeFilter">
        <date-range-filter :module="store" />
      </v-col>

      <template v-if="!isLeagueManagerForCurrentSeason && moduleIsGame">
        <v-col cols="auto">
          <game-place-filter :module="store"/>
        </v-col>

        <v-col cols="auto" :class="$style.opponentsFilter">
          <opponent-filter :module="store"/>
        </v-col>
      </template>

      <template v-if="isLeagueManagerForCurrentSeason && moduleIsGame">
        <v-col cols="auto" :class="$style.teamsFilter">
          <teams-filter :module="store"/>
        </v-col>
      </template>

      <template v-if="moduleIsPractice && availableTeamIDs.length>1">
        <v-col cols="auto">
          <TeamSelectorFilter :module="store" :teams="availableTeams" />
        </v-col>
        <v-col cols="auto">
          <AttendantsFilter :module="store" :teamId = "selectedTeamId" v-if="selectedTeamId>0" />
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<style lang="scss" module>
.dateRangeFilter {
  min-width: 20em;
}
.opponentsFilter {
  min-width: 25em;
}
.teamsFilter {
  max-width: calc(100% - 8em - 20em);
}
</style>
