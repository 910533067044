export default {
  default() {
    return {
      UId: {
        // v-for unique
        type: String,
        required: true,
      },
      Id: {
        // TagID or PlayerID (server)
        type: Number,
        required: false,
      },
      Avatar: {
        type: String,
        required: false,
        default: process.env.VUE_APP_DEFAULT_PLAYER_AVATAR,
      },
      LastName: {
        type: String,
        required: true,
        default: '',
      },
      FirstName: {
        type: String,
        required: true,
        default: '',
      },
      DisplayName: {
        type: String,
        required: false,
        default: '',
      },
      FormattedName: {
        type: String,
        required: true,
        default: '',
      },
      FormattedNameWithJersey: {
        type: String,
        required: true,
        default: '',
      },
      Height: {
        type: String,
        required: false,
        default: '',
      },
      Weight: {
        type: String,
        required: false,
        default: '',
      },
      Position: {
        type: String,
        required: false,
        default: '',
      },
      Bats: {
        type: String,
        required: false,
        default: '',
      },
      Throws: {
        type: String,
        required: false,
        default: '',
      },
    };
  },
};
