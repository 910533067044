import { mapGetters } from 'vuex';
import transform from 'lodash/transform';
import { Calculators, Enums, Filters, Mappers, Providers, Entities, Selectors, Formatters } from '@flightscope/baseball-stats';

import { SessionType } from '@/store/actions/api';
import { Session, LineupTag, BenchTag } from '@/models/orm/Hierarchy';

import { EReportContext } from '@/enums/reports';
import { ELineupType } from '@/enums/dto';

import { calculatePitchersStats, calculateBattersStats } from '@/providers/GameSummaryStatsProvider';
import Wrapper from '../../../components/ui/Wrapper.vue';
import BoxScore from '../components/Lineup/BoxScore.vue';
import LineupSingle from '../components/Lineup/LineupSingle.vue';
import LineupHeading from '../components/Lineup/LineupHeading.vue';
import LineupTable from '../components/Lineup/LineupTable.vue';
import ScoreBoard from '../components/ScoreBoard.vue';

export default {
  name: 'Summary',

  inject: ['repo'],

  props: {
    session: {
      type: Session,
      required: true,
    },

    stats: {
      type: Array,
      default: () => [],
    },

    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },
  },

  components: {
    Wrapper,
    LineupSingle,
    LineupHeading,
    LineupTable,
    ScoreBoard,
    BoxScore,
  },

  data() {
    return {
      loading: false,
      error: null,
      Enums,
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),

    homeLineup() {
      return this.session.homeLineup;
    },

    awayLineup() {
      return this.session.awayLineup;
    },

    tableItems() {
      switch (this.session.sessionType) {
        case SessionType.GAMES:
        case SessionType.PRACTICE:
        case SessionType.LIVEBP:
          return [EReportContext.BATTING, EReportContext.PITCHING];
        case SessionType.PITCHING:
          return [EReportContext.PITCHING];
        case SessionType.HITTING:
          return [EReportContext.BATTING];
        default:
          return [];
      }
    },

    teamItems() {
      let out = [];
      if (this.session.awayLineup) {
        out.push(ELineupType.AWAY);
      }
      if (this.session.homeLineup) {
        out.push(ELineupType.HOME);
      }
      return out;
    },

    headers() {
      const batting = [
        Enums.BattingRankingStats.AtBats,
        Enums.BattingRankingStats.Runs,
        Enums.BattingRankingStats.Hits,
        Enums.BattingRankingStats.RunsBattedIn,
        Enums.BattingRankingStats.Walks,
        Enums.BattingRankingStats.Strikeouts,
        Enums.BattingRankingStats.BattingAverage,
      ];

      const pitching = [
        // TODO - update after new stats are available
        Enums.PitchingRankingStats.NumberOfPitches,
        Enums.PitchingRankingStats.InningsPitched,
        Enums.PitchingRankingStats.HitsAllowed,
        Enums.PitchingRankingStats.RunsAllowed,
        // Enums.PitchingRankingStats.EarnedRuns,
        Enums.PitchingRankingStats.WalksAllowed,
        Enums.PitchingRankingStats.Strikeouts,
        Enums.PitchingRankingStats.HomeRunsAllowed,
        Enums.PitchingRankingStats.EarnedRunsAverage,
      ];

      if (this.session.hasExtendedTagging) {
        return {
          batting: [
            Enums.BattingRankingStats.NumberOfPitches,
            Enums.BattingRankingStats.ZContactRate,
            Enums.BattingRankingStats.ExitSpeedMax,
          ],
          pitching: [
            Enums.PitchingRankingStats.NumberOfPitches,
            Enums.PitchingRankingStats.KZoneRate,
            Enums.PitchingRankingStats.WhiffRate,
          ],
        };
      }

      if (this.session.mightHaveScoring) {
        return {
          batting: [
            Enums.BattingRankingStats.AtBats,
            Enums.BattingRankingStats.Runs,
            Enums.BattingRankingStats.Hits,
            Enums.BattingRankingStats.RunsBattedIn,
            Enums.BattingRankingStats.Walks,
            Enums.BattingRankingStats.Strikeouts,
            Enums.BattingRankingStats.BattingAverage,
          ],
          pitching: [
            // TODO - update after new stats are available
            Enums.PitchingRankingStats.NumberOfPitches,
            Enums.PitchingRankingStats.InningsPitched,
            Enums.PitchingRankingStats.HitsAllowed,
            Enums.PitchingRankingStats.RunsAllowed,
            // Enums.PitchingRankingStats.EarnedRuns,
            Enums.PitchingRankingStats.WalksAllowed,
            Enums.PitchingRankingStats.Strikeouts,
            Enums.PitchingRankingStats.HomeRunsAllowed,
            Enums.PitchingRankingStats.EarnedRunsAverage,
          ]
        };
      }
      return {
        batting: [],
        pitching: [],
      };
    },

    lineup() {
      if (!this.session) {
        return null;
      }

      const pitching = calculatePitchersStats(this.session.resultsv1);
      const batting = calculateBattersStats(this.session.resultsv1);

      let home = { pitching: [], batting: [] };
      let away = { pitching: [], batting: [] };

      // we will get results grouped per tag Id here, no need to complicate life
      for (let index = 0; index < pitching.length; index++) {
        const tagId = parseInt(pitching[index].TagID, 10);
        let { stats } = pitching[index];

        // try with home roster, no placeholderFallback
        if (tagId === 0) {
          this.$log.warn('TagId is not set in pitching stats');
          continue;
        }

        if (!this.session.hasPlayersLoaded) {
          continue;
        }

        let homePlayer = this.session.homeLineup.team.players.find((p) => p.id === tagId);
        let awayPlayer;

        if (homePlayer) {
          home.pitching.push({
            player: homePlayer,
            stats,
          });
        } else if (this.session.awayLineup) {
          awayPlayer = this.session.awayLineup.team.players.find((p) => p.id === tagId);

          if (awayPlayer) {
            away.pitching.push({ player: awayPlayer, stats });
          }
        }

        if (!homePlayer && !awayPlayer) {
          let unkPlayer = this.PlayerRepo.getDefault(tagId);

          home.pitching.push({
            player: unkPlayer,
            stats,
          });
          away.pitching.push({
            player: unkPlayer,
            stats,
          });
        }
      }

      for (let index = 0; index < batting.length; index++) {
        const tagId = parseInt(batting[index].TagID, 10);
        let { stats } = batting[index];

        // try with home roster, no placeholderFallback
        if (tagId === 0) {
          this.$log.warn('TagId is not set in batting stats');
          continue;
        }

        if (!this.session.hasPlayersLoaded) {
          continue;
        }

        let homePlayer = this.session.homeLineup.team.players.find((p) => p.id === tagId);
        let awayPlayer;

        if (homePlayer) {
          home.batting.push({
            player: homePlayer,
            stats,
          });
        } else if (this.session.awayLineup) {
          awayPlayer = this.session.awayLineup.team.players.find((p) => p.id === tagId);

          if (awayPlayer) {
            away.batting.push({ player: awayPlayer, stats });
          }
        }

        if (!homePlayer && !awayPlayer) {
          let unkPlayer = this.PlayerRepo.getDefault(tagId);

          home.batting.push({
            player: unkPlayer,
            stats,
          });
          away.batting.push({
            player: unkPlayer,
            stats,
          });
        }
      }
      return {
        away,
        home,
      };
    },

    rankingStats() {
      let out = this.tableItems.reduce((acc, curr, index) => {
          acc[curr.key] = this.statsPerContext(curr.key);
        return acc;
      }, {});

      return out;
    },
  },

  methods: {
    headersDefaultStats(headers) {
      const getDefFn = Entities.RankingStat.getDefault;
      return headers.reduce((acc, curr, index) => {
        acc[curr.key] = getDefFn(curr, 1);

        if (acc[curr.key].value === null) {
          acc[curr.key].value = '-';
        }

        return acc;
      }, {});
    },

    addDerivatives(stats, context) {
      if (Object.keys(stats).length === 0) {
        return stats;
      }

      const contextHeaders = this.headers[context];
      const selector = new Selectors.DerivativeRankingStatCalculatorSelector();
      const provider = new Providers.DerivativeRankingStatsProvider(selector);

      if (context === Enums.RankingScopeContext.Pitching.key) {
        selector.register(
            new Calculators.DerivativeRankingStatCalculators.InningsPitchedRankingStatCalculator(
              new Formatters.InningsPitchedFormatter()
            )
        );
        selector.register(
          new Calculators.DerivativeRankingStatCalculators.EarnedRunsAverageRankingStatCalculator()
        );
        selector.register(new Calculators.DerivativeRankingStatCalculators.StrikeoutMinusWalkPercentageStatCalculator(),);
        selector.register(Calculators.DerivativeRankingStatCalculators.PitchingRatioRankingStatCalculator);
        selector.register(new Calculators.DerivativeRankingStatCalculators.OnBasePlusSluggingStatCalculator(context));
        selector.register(new Calculators.DerivativeRankingStatCalculators.EarnedRunsAverageRankingStatCalculator());
      }
      else if (context === Enums.RankingScopeContext.Batting.key) {
        selector.register(new Calculators.DerivativeRankingStatCalculators.ExtraBaseHitRankingStatCalculator());
        selector.register(Calculators.DerivativeRankingStatCalculators.BattingRatioRankingStatCalculator);
        selector.register(new Calculators.DerivativeRankingStatCalculators.OnBasePlusSluggingStatCalculator(context));
      }

      return transform(
        stats,
        (r, v, k) => {
          const stats = Object.values(v).map(
            param => Entities.RankingStat.fromObject(param)
          );

          let calculated = provider.calculate(stats, contextHeaders, context, true, false);

          calculated = calculated.reduce((acc, curr, i) => {
            acc[curr.parameter] = curr;
            if (acc[curr.parameter].value === null) {
              acc[curr.parameter].value = '-';
            }
            return  acc;
          }, {});

          r[k] = calculated;
        },
        {},
      );
    },

    statsPerContext(context) {
      const aggregator = new Calculators.RankingStatsAggregator();
      const filter = new Filters.RankingScopesFilter();
      const mapper = new Mappers.RankingStatsMapper(new Mappers.RankingStatParametersMapper());

      const contextHeaders = this.headers[context];

      const localStats = this.stats;

      const contextDefaultStats = this.headersDefaultStats(contextHeaders);

      const stats = filter.filter(localStats, { context });

      const statsPerPlayer = aggregator.aggregate(stats, undefined, [Enums.RankingScope.tagId.key]);
      const perPlayer = mapper.mapCollection(statsPerPlayer);

      let perPlayerWithDefault = this.addDerivatives(perPlayer, context);

      const statsPerTeam = aggregator.aggregate(stats, undefined, [Enums.RankingScope.teamId.key]);
      const perTeam = mapper.mapCollection(statsPerTeam);
      let perTeamWithDefault = this.addDerivatives(perTeam, context);

      const boxScoreSummaryProvider = new Providers.BoxScoreSummaryProvider(aggregator, filter);

      const teams = Object.keys(perTeam);

      let boxScore;

      if (context === EReportContext.BATTING.key) {
        boxScore = teams.reduce((acc, curr, all) => {
          acc[curr] = boxScoreSummaryProvider.getBattingSummary(localStats, Number(curr));
          return acc;
        }, {});
      } else if (context === EReportContext.PITCHING.key) {
        boxScore = teams.reduce((acc, curr, all) => {
          acc[curr] = boxScoreSummaryProvider.getPitchingSummary(localStats, Number(curr));
          return acc;
        }, {});
      }

      return {
        perPlayer: perPlayerWithDefault,
        perTeam: perTeamWithDefault,
        boxScore,
        defaultStats: contextDefaultStats,
      };
    },
  },
};
