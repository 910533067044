<template>
  <v-container fluid class="pa-0" align="start">
    <v-row no-gutters class="pt-1">
      <v-col>
        <v-slide-group
          :multiple="false"
          :mandatory="false"
          :show-arrows="false"
          :center-active="false"
        >
          <template v-for="({ component, show, styleClass, props, filterGroup, filterKey, value, hide }, i) in localFilters">
          <v-col
            :key="`${filterGroup}-${filterKey}-${i}`"
            cols="auto"
            :class="[...styleClass]"
            v-if="!hide"
          >
            <component
              :is="component || 'custom-select'"
              :value="value"
              v-on:input="($event) => updateFilters($event, filterGroup, filterKey)"
              v-show="show"
              v-bind="props"
            ></component>
          </v-col>
          </template>

          <template
            v-for="({ component, show, styleClass, props, listeners, filterGroup, filterKey, value, hide }, i) in enabledFilters"
          >
          <v-col
            :key="`${filterGroup}-${filterKey}`"
            cols="auto"
            :class="[...styleClass]"
            v-if="!hide"
          >
            <component
              :is="component || 'custom-select'"
              :value="value"
              v-on="listeners"
              v-on:input="($event) => updateFilters($event, filterGroup, filterKey)"
              v-show="show"
              v-bind="props"
            ></component>
          </v-col>
          </template>
        </v-slide-group>
      </v-col>

      <v-col cols="auto" class="py-2 d-inline-flex align-center">
        <v-btn class="size--custom" large icon @click.stop="dialog = true">
          <v-icon>$vuetify.icons.settings</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <table-columns-dialog :indexesOfAvailableColumns='indexesOfAvailableColumns' v-model="dialog"></table-columns-dialog>
  </v-container>
</template>

<script>
import get from 'lodash/get';
import {
  Enums
} from '@flightscope/baseball-stats';
import { Session } from '@/models/orm/Hierarchy';
import UniqueValueForFilters from '@/components/mixins/UniqueValueForFilters';
import FilterStores from '@/enums/StoreKeys';
import ResultFilter from '@/filters/ResultFilter';
import TableColumnsDialog from '@/components/ui/dialogs/TableColumnsDialog.vue';

import ScoringCountsFilter from '@/components/filters/ScoringCountsFilter.vue';
import PitchResultDropdown from '@/components/filters/PitchResultDropdown.vue';
import PlayOutcomeDropdown from '@/components/filters/PlayOutcomeDropdown.vue';
import PitchTypeDropdown from '@/components/filters/PitchTypeDropdown.vue';
import CustomSelect from '@/components/ui/selection-controls/CustomSelect.vue';
import ZoneDropdown from '@/components/ui/dropdowns/ZoneDropdown.vue';

import { SET_DATA_TABLE_FILTERS, CLEAR_DATA_TABLE_FILTERS } from '@/store/actions/session';
import { defaultSessionPlayerWithID, mapOptionsToSelect } from '@/utils/mappers';

export default {
  name: 'DataTableFilters',

  inject: ['repo'],

  mixins: [UniqueValueForFilters],

  props: {
    forceClearFilters: {},

    indexesOfAvailableColumns: {},

    session: {
      type: Session,
      required: true,
    },

    loaded: {
      type: Boolean,
      default: false,
    },

    SessionRepo: {
      default() {
        return this.repo.get('sessions');
      },
    },
  },

  components: {
    ScoringCountsFilter,
    PitchResultDropdown,
    PlayOutcomeDropdown,
    PitchTypeDropdown,
    ZoneDropdown,
    CustomSelect,
    TableColumnsDialog,
  },

  data() {
    return {
      dialog: false,
      requiredFilters: [],
      filters_key: FilterStores.DATA_TABLE,
    };
  },

  computed: {
    filter() {
      return ResultFilter;
    },

    dataFilters() {
      return this.session?.filters;
    },

    localFilters() {
      return [
        {
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.BatterHandedness.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.BatterHandedness.key], ''),
          props: {
            label: this.filter.BatterHandedness.name,
            items: this.getFilteredOptions(this.filter.BatterHandedness.key,),
          },
          styleClass: [this.$style.handedessCol],
          show: this.loaded,
        },
        {
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.Batters.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.Batters.key], []),
          props: {
            label: this.filter.Batters.name,
            items: this.battersList,
            multiple: true,
            showAll: true,
            comp: 'v-autocomplete',
          },
          styleClass: [this.$style.playersCol],
          show: this.loaded,
        },
        {
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.PitcherHandedness.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitcherHandedness.key], ''),
          props: {
            label: this.filter.PitcherHandedness.name,
            items: this.getFilteredOptions(this.filter.PitcherHandedness.key),
          },
          styleClass: [this.$style.handedessCol],
          show: this.loaded,
        },
        {
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.Pitchers.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.Pitchers.key], []),
          props: {
            label: this.filter.Pitchers.name,
            items: this.pitchersList,
            multiple: true,
            showAll: true,
            comp: 'v-autocomplete',
          },
          styleClass: [this.$style.playersCol],
          show: this.loaded,
        },
        {
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.PitchSet.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitchSet.key], ''),
          props: {
            label: this.filter.PitchSet.name,
            items: this.getFilteredOptions(this.filter.PitchSet.key),
          },
          styleClass: [this.$style.shortFilter],
          show: this.loaded,
          if: this.isBaseball,
          hide: !this?.session?.mightHaveTagging,
        },
        {
          component: 'zone-dropdown',
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.Zones.key,
          props: {
            label: this.filter.Zones.name,
            selectedItems: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.Zones.key], []),
          },
          styleClass: [this.$style.shortFilter],
          hide: !this.session.mightHaveRadarData,
          show: this.loaded,
        },

        {
          // TODO: use proper component
          component: 'play-outcome-dropdown',
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.PitchResult.key,
          value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitchResult.key], []),
          props: {
            label: this.filter.PlayOutcome.name,
            multiple: true,
            selectedItems: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitchResult.key], []),
            possibleOptions: this.getPossibleOptions(this.filter.PlayOutcome.key),
            hasExtendedTagging: this.session.hasExtendedTagging
          },
          styleClass: [this.$style.multichipCol],
          hide: !this.session.v2 || !this?.session?.mightHaveScoring,
          show: this.loaded,
        },

        {
          component: 'pitch-result-dropdown',
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.PitchResult.key,
          props: {
            selectedItems: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitchResult.key], []),
            label: this.filter.PitchResult.name,
            multiple: true,
            // possibleOptions: this.getPossibleOptions(this.filter.PitchResult.key)
          },
          styleClass: [this.$style.multichipCol],
          show: this.loaded,
          hide: this.session.v2 || !this?.session?.mightHaveTagging,
        },

        {
          component: 'pitch-type-dropdown',
          filterGroup: FilterStores.DATA_TABLE,
          filterKey: this.filter.PitchType.key,
          props: {
            selectedItems: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.PitchType.key], []),
            label: this.filter.PitchType.name,
            multiple: true,
            possibleOptions: this.getPossibleOptions(this.filter.PitchType.key)
          },
          styleClass: [this.$style.multichipCol],
          show: this.loaded,
          hide: !this?.session?.mightHaveTagging,
        },
      ];
    },

    enabledFilters() {
      let displayedFilters = [];

      if (this.session && this.session.mightHaveScoring) {
        displayedFilters = [
          ...displayedFilters,
          {
            filterGroup: FilterStores.DATA_TABLE,
            filterKey: this.filter.ScoringOuts.key,
            value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.ScoringOuts.key], []),
            props: {
              label: this.filter.ScoringOuts.name,
              items: [
                { value: 0, text: 'None' },
                { value: 1, text: 'One' },
                { value: 2, text: 'Two' }
              ],
              multiple: true,
            },
            styleClass: [this.$style.selectWithInlineFlex],
            show: this.loaded && this.session.mightHaveScoring
          },
          {
            component: 'scoring-counts-filter',
            filterGroup: FilterStores.DATA_TABLE,
            filterKey: this.filter.ScoringCounts.key,
            value: undefined,
            listeners: {
              'update:balls': ($event) => this.updateFilters($event, FilterStores.DATA_TABLE, this.filter.ScoringBalls.key),
              'update:strikes': ($event) => this.updateFilters($event, FilterStores.DATA_TABLE, this.filter.ScoringStrikes.key),
            },
            props: {
              label: this.filter.ScoringCounts.name,
              balls: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.ScoringBalls.key], ''),
              strikes: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.ScoringStrikes.key], ''),
              multiple: true,
            },
            styleClass: [this.$style.shortFilter],
            show: this.loaded && this.session.mightHaveScoring
          },
          {
            filterGroup: FilterStores.DATA_TABLE,
            filterKey: this.filter.ScoringRunnersOn.key,
            value: get(this.dataFilters, [FilterStores.DATA_TABLE, this.filter.ScoringRunnersOn.key], ''),
            props: {
              label: this.filter.ScoringRunnersOn.name,
              items: mapOptionsToSelect(this.filter.ScoringRunnersOn.key),
            },
            styleClass: [this.$style.shortFilter],
            show: this.loaded && this.session.mightHaveScoring,
          },
        ];
      }

      return displayedFilters;
    },

    battersList() {
      if (this.loaded) {
        let { batters } = this.session;
        return this.SessionRepo.mapPlayersToOptions(this.session, batters);
      }
      return [];
    },

    pitchersList() {
      if (this.loaded) {
        let { pitchers } = this.session;
        return this.SessionRepo.mapPlayersToOptions(this.session, pitchers);
      }
      return [];
    },

    isBaseball() {
      return process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key;
    }
  },
  methods: {
    updateFilters(val, group, key) {
      this.dataFilters[group][key] = val;
      Session.update({
        id: this.session.id,
        filters: this.dataFilters,
      });
      this.$emit('update:filters');
    },

    watchDataSet(isSet) {
      if (isSet && this.session) {
        this.getPossibleParameters(this.storeFilters);
      }
    },
  },

  mounted() {
    if (this.forceClearFilters) {
      this.$store.dispatch(CLEAR_DATA_TABLE_FILTERS);
    }
  },

  watch: {
    loaded: {
      handler: 'watchDataSet',
      immediate: true,
    },
  },
};
</script>

<style lang="scss" module>
.selectWithInlineFlex {
  max-width: 12em;
}
.handedessCol {
  max-width: 9em;
}
.shortFilter {
  max-width: 9em;
}
.multichipCol {
  min-width: 5em;
  max-width: 11em;
}
.playersCol {
  max-width: 15em;
}
</style>

<style lang="scss">
.v-btn--icon.size--custom {
  height: 32px;
  width: 32px;

  .v-icon {
    height: 32px;
    font-size: 32px;
    width: 32px;

    svg {
      height: 32px;
      font-size: 32px;
      width: 32px;
    }
  }
}
</style>
