<script>
import Vue from 'vue';
import { sleep } from '@/utils/helpers';

import SessionInfo from '../components/SessionInfo.vue';
import SessionNavigator from '../components/SessionNavigator.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SingleSession',

  components: {
    SessionInfo,
    SessionNavigator,
  },

  inject: ['repo'],

  props: {
    id: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    SessionRepo: {
      default() {
        return this.repo.get('sessions');
      },
    },

    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },

    Session: {
      default() {
        return this.$store.$db().model('sessions');
      },
    },

    TeamRepo: {
      default() {
        return this.repo.get('teams');
      },
    },

    MediaRepo: {
      default() {
        return this.repo.get('media');
      },
    },
  },

  metaInfo() {
    return {
      title: `${this.type} session ${this.id}`,
    };
  },

  data() {
    return {
      loading: false,
      session: undefined,
      sessionStats: undefined,
      error: undefined,

      deletingVideo: false,
      deletedVideo: false,
      videoIdToDelete: undefined,
      deletedVideoIds: [],
    };
  },

  computed: {
    ...mapGetters({
      isDataTablePage: 'session/isDataTablePage'
    }),
    dialogDeleteVideo: {
      get() {
        return !!this.videoIdToDelete || this.deletedVideo;
      },
      set() {
        this.videoIdToDelete = undefined;
      }
    },
  },

  methods: {
    async updateLineupTeams(lineups) {
      if (!Array.isArray(lineups) || !lineups.length) {
        return null;
      }

      this.$log.debug('Loading teams for session');

      for (let i = 0; i < lineups.length; i++) {
        if (!lineups[i].team) {
          // eslint-disable-next-line no-await-in-loop
          let team = await this.TeamRepo.get(lineups[i].teamID);
        }
      }

      let { error, session } = await this.SessionRepo.get(this.id);

      return session;
    },

    async updateLineupPlayers(lineups) 
    {
      if (!Array.isArray(lineups) || !lineups.length) {
        return null;
      }
      this.$log.debug('Loading teams for session');

      
      for (let i = 0; i < lineups.length; i++) {
        let team = lineups[i].team;
        if (team && !team.fetchedPlayers) {
          // eslint-disable-next-line no-await-in-loop
          let players = await this.PlayerRepo.getForTeam(team.id);
          

          const missedPlayerIds = lineups[i].playerIds
            .filter(playerId => !players.find(p => p.id === playerId));

            for (const playerId of missedPlayerIds) {
              // eslint-disable-next-line no-await-in-loop
              await this.PlayerRepo.fetchPlayer(playerId)
            }
        }
      }
          
      let { error, session } = await this.SessionRepo.get(this.id);

      return session;
    },

    async maybeLoadPlayers(val, oldVal) {
      if (this.session.lineups.length && this.session.hasTeamsLoaded && !this.session.hasPlayersLoaded) {
        this.session = await this.updateLineupPlayers(this.session.lineups);
      }
    },

    async maybeLoadData(val, oldVal) {
      if (this.session.lineups.length && !this.session.hasTeamsLoaded) {
        this.session = await this.updateLineupTeams(this.session.lineups);
      }

     this.sessionStats = await this.SessionRepo.fetchStats(this.session.id);
    },

    async fetchSession(id, ttl=true) {
      if (!id) {
        this.error = 'Session id was not provided.';
        return { error: this.error };
      }
      let { error, session } = await this.SessionRepo.get(id, { ttl });

      return error ? { error } : { session };
    },

    async fetchData(to, from) {
      this.error = null;

      if (!to?.id || !to?.type) {
        this.error = 'Session id or type was not provided.';
        return;
      }

      this.loading = true;

      let error;
      let session;

      if (to.id !== from?.id) {
        ({ error, session } = await this.fetchSession(Number(to.id)));
      } else {
        ({ error, session } = await this.fetchSession(Number(to.id), false));
      }

      if (error) {
        this.session = null;
        this.sessionStats = null;
        this.error = error.toString();
      } else if (session !== this.session) {
        this.session = session;
      }

      this.loading = false;
    },

    refreshSession() {
      this.session = this.SessionRepo.getSingle(this.id);
    },

    async deleteVideo() {
      this.deletingVideo = true;
      this.deletedVideo = false;

      const { message, error } = await this.MediaRepo.deleteMedia(this.videoIdToDelete);

      if (message) {
        await this.SessionRepo.deleteMediaForResult(this.videoIdToDelete);

        await this.refreshSession();

        this.deletedVideo = true;
        await sleep(3000);
        this.deletedVideo = false;
      }

      this.deletingVideo = false;
      this.videoIdToDelete = undefined;
    },
  },

  watch: {
    '$route.params': {
      handler: 'fetchData',
      immediate: true,
    },

    'session.id': {
      handler: 'maybeLoadData',
    },

    'session.hasTeamsLoaded': {
      handler: 'maybeLoadPlayers'
    },
  },
});
</script>

<template>
  <v-sheet color="grey lighten-4" flat tile width="100%">
    <v-overlay v-if="loading" absolute>
      <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
    </v-overlay>

    <v-alert type="error" v-if="error">{{ error }}</v-alert>

    <session-info
      v-if="session"
      :isSlim="isDataTablePage"
      :loading="loading"
      :session="session"
      :refresh-session="refreshSession"
    ></session-info>

    <session-navigator
      v-if="session" :loading="loading || !session"
      :isSlim="isDataTablePage"
      :session="session"
      :disabled="!session || !session.hasTeamsLoaded || !session.hasPlayersLoaded"
    ></session-navigator>

    <v-container fluid class="session-container pa-0 scrolled-max-h" :class="{slim: isDataTablePage}" v-if="session">
      <v-fade-transition tag="div" group class="session-container scrolled-max-h" :class="{slim: isDataTablePage}">
        <router-view
          class="view"
          :session="session"
          :refresh-session="refreshSession"
          :key="$route.name.split('.').slice(0,2).join('-')"
          :stats="sessionStats"
          @delete:video="(id) => (videoIdToDelete = id)"
        ></router-view>
      </v-fade-transition>
    </v-container>

    <v-dialog v-model="dialogDeleteVideo" :persistent="deletingVideo" v-if="dialogDeleteVideo" max-width="500px">
        <v-card>
          <v-card-title class="d-block body-1 text-center">
            <span v-if="deletingVideo">{{ $vuetify.lang.t('Deleting video...') }}</span>
            <span v-else-if="deletedVideo">{{ $vuetify.lang.t('Video was deleted') }}</span>
            <span v-else>{{ $vuetify.lang.t('Video will be deleted permanently, are you sure?') }}</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="videoIdToDelete = undefined"
              :disabled="deletingVideo"
              v-if="!deletingVideo && !deletedVideo"
              >Cancel</v-btn
            >
            <v-btn v-if="deletedVideo" text color="blue darken-1">
              <v-icon color="success">mdi-check</v-icon>
            </v-btn>
            <v-btn
              v-else
              color="blue darken-1"
              text
              @click="deleteVideo"
              :loading="deletingVideo"
              :disabled="deletingVideo"
            >
              {{ $vuetify.lang.t('Delete') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-sheet>
</template>

<style scoped>
.session-container {
  height: 100%;
  --max-h: calc(100vh - 60px - 101px - 48px);
}
.session-container.slim {
  --max-h: calc(100vh - 60px - 83px - 28px);
}
</style>
