<template>
<v-card class="pa-0">
  <v-container class="pa-4">
    <v-row>
      <v-col cols="auto">
        <player-avatar v-model="localPlayer.AvatarURL" size="64"></player-avatar>
      </v-col>
      <v-col class="pl-4">
        <p class="title">{{ localPlayer.FormattedName }}</p>
        <p class="body-2 mb-0">
          <strong>No.:</strong> {{ localPlayer.Number || localPlayer.JerseyNumber || '-' }} <br />

          <strong>Height:</strong>
          {{ localPlayer.Height | convertUnit('HEIGHT_SHORT_FTIN', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: false }) }}
          <br />

          <strong>Weight:</strong>
          {{ localPlayer.Weight | convertUnit('WEIGHT', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 0 }) }}
          <br />

          <strong>Position:</strong> {{ localPlayer.PrimaryPosition | playerPosition }}<br />

          <strong>Batting:</strong> {{ localPlayer.Bats | handedness }}<br />

          <strong>Throwing:</strong> {{ localPlayer.Throws | handedness}}
        </p>
      </v-col>
    </v-row>
  </v-container>
</v-card>
</template>

<script>
import PlayerAvatar from './PlayerAvatar.vue';
// import ImageLoader from '../ui/ImageLoader.vue';
import defaultPlayerData from '../../defaults/playerData';

export default {
  name: 'PlayerTooltip',
  components: {
    // ImageLoader,
    PlayerAvatar,
  },
  props: {
    playerData: {
      type: Object,
      required: true,
      default: defaultPlayerData,
    },
    unitsSystem: {
      requred: true,
    },
  },

  computed: {
    localPlayer() {
      return this.playerData?.player || this.playerData;
    },
  },
};
</script>
