<script>
import { Session } from '@/models/orm/Hierarchy';
import LinksPerRouteProvider from '@/providers/LinksPerRouteProvider';
import Wrapper from '@/components/ui/Wrapper.vue';

export default {
  name: 'SessionNavigator',

  components: {
    Wrapper,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    session: {
      type: Session,
      required: true,
    },
    isSlim: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      links: [],
    };
  },

  mounted() {
    this.$log.debug('SessionNavigator mounted.');
    this.links = LinksPerRouteProvider.getLinksForRoute(
      this.$route.name,
      this.$route.params,
    );

    if (!this.session.mightHaveTwoTeams) {
      for (let index = 0; index < this.links.length; index++) {
        if (this.links[index].to.name === 'session.summary') {
          this.links[index].label = 'Player List';
        }
      }
    }
  },

  computed: {
    sessionNotLoading() {
      this.$log.debug(this.$route.name, this.$route.params);
      return true;
    },
  },
};
</script>

<template>
<v-sheet tile color="header_bg" class="session-navigator">
  <v-container fluid class="py-0">
    <v-row class="my-0">
      <wrapper class="py-0">
        <v-tabs
          class="session-tabs"
          :class="{slim: isSlim}"
          dark
          tile
          background-color="header_bg"
          slider-color="primary"
          fixed-tabs
          centered
        >
          <v-tab
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            :disabled="disabled"
            link
          >{{ link.label }}</v-tab>
        </v-tabs>
      </wrapper>
    </v-row>
  </v-container>
</v-sheet>
</template>

<style lang="scss" scoped>
.session-tabs {
  .v-tab:not(.v-tab--active) {
    color: inherit !important;
  }

  .v-tabs-slider {
    background-color: var(--v-anchor-base);
  }

  &.slim::v-deep {
    .v-tabs-bar {
      height: 36px;
    }
  }
}
</style>
