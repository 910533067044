<template>
<v-select
    v-model="selected"
    :disabled="this.filterIsLoading(this.module)"
    :items="opponentsItems"
    :menu-props="menuProps"
    item-text="displayName"
    item-value="id"
    label="Opponent"
    outlined
    dense
    hide-details
></v-select>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_TEAM } from '../../store/actions/sessions';
import { concatAndDeDuplicate } from '../../utils/helpers';


export default {
  name: 'OpponentFilter',
  props: ['module'],
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
      baseTeam: [{ id: '', displayName: 'All Teams' }],
    };
  },
  computed: {
    ...mapGetters('entities/teams', ['teamsForSeason']),

    ...mapGetters('app', ['filters']),

    ...mapGetters(['filterIsLoading']),

    opponentsItems() {
      return concatAndDeDuplicate(this.baseTeam, this.teamsForSeason(this.filters?.season.id));
    },

    isLoading() {
      return this.$store.state.sessions[this.module].filter.team_home;
    },

    selected: {
      get() {
        return this.$store.state.sessions[this.module].filter.opponents;
      },
      set(value) {
        this.$store.dispatch('SET_OPPONENTS', { module: this.module, value });
      },
    },
  },

};
</script>

<style lang="scss" module>
.col {
  max-width: 15em;
}
</style>
