<template>
  <v-card light>
    <v-container class="py-0">
      <v-row align="center" justify="space-between" :class="$style.header">
        <v-col cols="auto" class="flex-nowrap pl-4 py-3">
          <player-avatar :src="playerData.AvatarURL" class="mr-2"></player-avatar>
          <player-profile-link :id="playerData.id" context="pitching">
            <strong class="subtitle-1 font-weight-bold">{{ playerData.FormattedNameWithJersey }}</strong>
          </player-profile-link>
        </v-col>
        <v-col cols="auto" class="body-2 pr-4 py-3">
          <strong>Throwing:</strong>
          {{ playerData.Throws || '-' }}

          <strong class="pl-4">No.:</strong>
          {{ playerData.Number || playerData.JerseyNumber || '-' }}

          <strong class="pl-4">Height:</strong>
          {{ playerData.Height | convertUnit('HEIGHT_SHORT_FTIN', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: false })}}

          <strong>Weight:</strong>
          {{ playerData.Weight | convertUnit('WEIGHT', unitsSystem.system, { noValueFallback: '-', fractionalDigits: 0 })}}

          <strong>Position:</strong>
          {{ playerData.PrimaryPosition || playerData.Position || '-' }}
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <profile-pitching-summary
            :class="$style.table"
            :expandable="false"
            :player="player"
            :report="report"
            :dynamic-filters="dynamicFilters"
            tile
            flat
            @horizontalScroll="$emit('horizontalScroll',$event)"
          ></profile-pitching-summary>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { Enums } from '@flightscope/baseball-stats';
import ProfilePitchingSummary from '../../../modules/player-profiles/reports/ProfilePitchingSummary.vue';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import defaultPlayerData from '@/defaults/playerData';
import ThExt from '@/components/ui/tables/ThExt.vue';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';

export default {
  name: 'PlayerResultsStats',
  components: {
    PlayerAvatar,
    ThExt,
    ProfilePitchingSummary,
    PlayerProfileLink,
  },
  props: {
    unitsSystem: {
      required: true,
    },
    playerData: {
      type: Object,
      required: true,
      default: defaultPlayerData,
    },
    filters: {
      type: Object,
      default: () => {}
    },
    stats: {
      type: Array,
      required: true,
      default() {
        return {
          PitchType: {
            // 'FF','FT','SP','FK','FC','SI','SL','CH','CU','SV','SC','CC','UI','RI','DR','FA', 'KN'
            type: String,
            required: true,
            default: 'UI',
          },
          PitchTypeLabel: {
            // Four-seam fastball, Two-seam fastball ...
            type: String,
            required: true,
            default: 'Unidentified',
          },
          [Enums.PitchingStats.NumberOfPitches.key]: {
            type: Number,
            required: true,
          },
          [Enums.PitchingStats.PitchUsage.key]: {
            // 1 - 100
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.PitchSpeedAvg.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.PitchSpeedMax.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.PitchSpinAvg.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.PitchMovementHAvg.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.PitchMovementVAvg.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.HitExitSpeedAvg.key]: {
            // speed, -, 0-X
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.ExtensionAvg.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.KZoneRate.key]: {
            // 1 - 100
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.HitsAllowed.key]: {
            type: Number,
            required: true,
          },
          [Enums.PitchingStats.Strikeouts.key]: {
            type: Number,
            required: true,
          },
          [Enums.PitchingStats.StrikeoutRate.key]: {
            // 1 - 100
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.Whiffs.key]: {
            type: Number,
            required: true,
          },
          [Enums.PitchingStats.Swings.key]: {
            type: Number,
            required: true,
          },
          [Enums.PitchingStats.SwingRate.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.WhiffRate.key]: {
            // 1 - 100
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.SwingingStrikeRate.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.ZSwingRate.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.ZContactRate.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.OSwingRate.key]: {
            type: [String, Number],
            required: true,
          },
          [Enums.PitchingStats.OContactRate.key]: {
            type: [String, Number],
            required: true,
          },
        };
      },
    },

    report: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      pitchingStats: Enums.PitchingStats,
    };
  },

  computed: {
    player() {
      return {
        Id: this.playerData.id,
      };
    },

    dynamicFilters() {
      return {
        ...this.filters,
        pitcherIds: [this.playerData.id],
      };
    },
  },

  methods: {
    calcTotalFromArray(property) {
      return this.stats
        .map(stat => stat[property])
        .reduce((total, num) => total + num, 0);
    },
  },
};
</script>

<style lang="scss" module>
.table {
  th, td {
    height: 3em;
    color: var(--v-mine-shaft-base);
    text-decoration: none solid rgb(0, 0, 0);
    padding: 0 0.3em;
  }

  thead th {
    opacity: 0.8;
    font-size: 0.75em;
    font-weight: bold;
  }

  tbody td {
    font-size: 0.88em;
  }

  tfoot th {
    font-size: 0.88em;
  }
}
</style>
