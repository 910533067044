<template>
  <v-container fluid class="pa-0">
    <v-row align="center" class="d-flex mb-2">
      <v-col cols="auto" class="d-flex align-center flex-grow-1">
        <v-divider></v-divider>

        <team-logo-url
          v-if="this.awayLineup.team"
          :url="this.awayLineup.team.logo"
          :name="this.awayLineup.team.displayName"
          :short-code="this.awayLineup.team.ShortCode"
          class="px-2"
          displayName
        ></team-logo-url>

        <v-divider></v-divider>
      </v-col>

      <v-col cols="auto">
        <div class="text-center headline px-3">{{ context.label }}</div>
      </v-col>

      <v-col cols="auto" class="d-flex align-center flex-grow-1">
        <v-divider></v-divider>

        <team-logo-url
          v-if="this.homeLineup.team"
          :url="this.homeLineup.team.logo"
          :name="this.homeLineup.team.displayName"
          :short-code="this.homeLineup.team.ShortCode"
          class="px-2"
          displayName
        ></team-logo-url>

        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col :cols="tableColsCount" v-for="(teamStats, index) in tableItems" :key="index">
        <v-data-table
          :items-per-page="-1"
          hide-default-footer
          hide-default-header
          disable-sort
          :loading="loading"
          :headers="headers(index)"
          :items="teamStats[context.key]"
          :class="$style.lineupTable"
          item-key="lineupPosition"
        >
          <template v-slot:[`item.player`]="{ value }">
            <td>
              <v-tooltip
                top
                color="transparent"
                nudge-left="80"
                :content-class="$style.playerTooltip"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <player-avatar v-if="!session.v2" v-model="value.AvatarURL" size="30" class="mr-2"></player-avatar>
                    <player-profile-link :id="value.id" :context="context.key">
                      <strong v-if="value">{{ value.FormattedName }}</strong>, {{ value.PrimaryPosition }}
                    </player-profile-link>
                  </div>
                </template>
                <player-tooltip
                  :playerData="value"
                  :class="$style.playerTooltip"
                  :unitsSystem="unitsSystem"
                ></player-tooltip>
              </v-tooltip>
            </td>
          </template>

          <template #foot="{ items }" v-if="teamStats[context.key].length">
            <tfoot>
            <tr>
              <th :colspan="session.v2 ? 2 : 1">{{ text.footer.title }}</th>

              <th
                class="text-center"
                v-for="(col, i) in  statsHeaders(index)"
                :key="i"
              >{{ calcTotalFromArray(items, col) }}</th>
            </tr>
            </tfoot>
          </template>

          <template #header="{ props }" v-if="teamStats[context.key].length">
            <thead>
            <tr>
              <template v-for="(header, i) in props.headers">
                <th-ext
                  v-if="header.desc"
                  :key="i"
                  dfn
                  align="center"
                  :title="header.desc"
                >
                  {{ text.headers[header.text] }}
                </th-ext>
                <th :key="i" v-else-if="header.text">{{ $vuetify.lang.t(header.text) }}</th>
                <th :key="i" v-else></th>
              </template>
            </tr>
            </thead>
          </template>

          <template v-slot:no-results>
            <no-data padding="1.25rem" message="There are no results"></no-data>
          </template>

          <template v-slot:no-data>
            <no-data padding="1.25rem"></no-data>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Enums } from '@flightscope/baseball-stats';

import { Session } from '@/models/orm/Hierarchy';

import TeamLogoUrl from '../../../../components/team/TeamLogoUrl.vue';
import PlayerAvatar from '../../../../components/player/PlayerAvatar.vue';
import PlayerTooltip from '../../../../components/player/PlayerTooltip.vue';
import ThExt from '../../../../components/ui/tables/ThExt.vue';
import { SessionType } from '../../../../store/actions/api';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';
const NoData = () => import('@/components/core/NoData.vue');

import { EReportContext } from '@/enums/reports';

const messages = {
  headers: {
    title: 'Player',
    PlateAppearances: 'PA',
    NumberOfPitches: 'NP',
    Hits: 'H',
    StrikeOuts: 'SO',
    HomeRuns: 'HR',
  },
  footer: {
    title: 'Totals',
  },
};

export default {
  name: 'LineupTable',

  components: {
    TeamLogoUrl,
    PlayerAvatar,
    PlayerTooltip,
    ThExt,
    PlayerProfileLink,
    NoData,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },

    context: {
      type: Object,
      requred: true,
    },

    stats: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    unitsSystem: {
      requred: true,
    },
  },

  data() {
    return {
      open: true,
      text: messages,
      headerDesc: {
        pitching: Enums.PitchingStats,
        batting: Enums.BattingStats,
      },
    };
  },

  computed: {
    homeLineup() {
      return this.session?.homeLineup;
    },

    awayLineup() {
      return this.session?.awayLineup;
    },

    tableItems() {
      let out = {};
      if (this.awayLineup && this.stats?.away) {
        out.away = this.stats.away
      }
      out.home = this.stats.home;
      return out;
    },

    tableColsCount() {
      return 6;
      return this.session.type !== SessionType.PRACTICE ? 6 : 12;
    },
  },

  methods: {
    calcTotalFromArray(statsArray, property) {
      return statsArray.map(item => item.stats[property]).reduce((total, num) => total + num, 0);
    },

    expanded(items) {
      return items.filter(i => i.player.subs.length);
    },

    statsHeaders(index) {
      if (this.stats[index][this.context.key].length) {
        let first = this.stats[index][this.context.key].find(i => i.stats);

        if (first) return Object.keys(first.stats);
      }
      return [];
    },

    headers(index) {
      let output = [
        { text: 'Player' , value: 'player', },
      ];

      if (this.stats[index][this.context.key].length) {
        let additionalCols = this.statsHeaders(index).map(i => {
            let ctx = this.context.key;
            let dict = this.headerDesc[ctx];
            let key = i;

            if (!dict.hasOwnProperty(key)) {
              if (i === 'StrikeOuts') key = 'Strikeouts';

              if (this.context.key === EReportContext.PITCHING.key) {
                if (i === 'Hits') {
                  key ='HitsAllowed';
                }
                if (i === 'HomeRuns') {
                  key ='HomeRunsAllowed';
                }
              }
            }
            return { text: i, value: `stats.${i}`, desc: dict[key].description, align: 'center' };
          });

        output = [
          ...output,
          ...additionalCols,
        ];
      }

      return output;
    },
  },
};
</script>

<style lang="scss" module>
.lineupTable {
  thead th {
    font-size: 12px;
    height: 46px;
  }
  tbody td {
    font-size: 14px;
    height: 40px;
  }
  tfoot td {
    font-size: 14px;
    height: 40px;
  }
}

.playerTooltip {
  opacity: 1 !important;
}
</style>
